import React, { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';
import HeaderComponent from '../components/HeaderComponent';
import { useNavigate } from 'react-router-dom';
import Loader from '../config/Loader';
import moment from 'moment-timezone';
import '../css/Iterations.css'

// import Table from 'react-bootstrap/Table';
const constants = require('../config/constants.js');


export default function Iterations() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [iterations, setIterations] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${constants.BACKEND_SERVER_BASEURL}/iterations/getAllIterations`)
      .then((response) => {
        setIterations(response.data);
        console.log(iterations)
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching iterations:', error);
        setLoading(false);
      });
  }, []);

  const viewIteration = (iterationId, companyId) => {
    navigate(`/SummaryReport/${iterationId}/${companyId}`);
  };

  return (
      <div className='iterations-container'>
        <HeaderComponent />
        <div style={{ display: 'flex', justifyContent: 'center', padding:'50px 0px 50px 0px',  }}>
          
          {loading ? (
            <Loader />
          ) : (
            <table className="table table-hover table-light" style={{ maxWidth: '75%', borderRadius:'10px' }}>
              <thead>
                <tr>
                  <th>Iteration</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {iterations.map((iteration) => (
                  <tr key={iteration.iteration_id}>
                    <td className={`align-middle`}>
                      {moment
                        .utc(iteration.inserted_date_time)
                        .local()
                        .format('DD MMM YYYY hh:mm A')}
                    </td>
                    <td className={`align-middle`}>{iteration.iteration_name}</td>
                    <td className={`align-middle`}>{constants.ITERATION_STATUS_IDS[iteration.iteration_status_id]}</td>
                    <td className={`align-middle`}>{(iteration.iteration_status_id === 3) && <button className={`btn btn-light`} onClick={viewIteration.bind(null, iteration.iteration_id, iteration.company_id)}>VIEW</button>}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
  );
}
