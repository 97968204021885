import {useState, useEffect, useContext} from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import NavbarContext from '../components/NavbarContext'
import '../css/MyDocumentsBar.css';
import { IoGrid } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";
import DocGridView from './DocGridView';
import DocListView from './DocListView';
import constants from '../config/constants';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';


const MyDocumentsBar = () => {
  const [currentView, setCurrentView] = useState('grid');
  const [allJurisdictions, setAllJurisdictions] = useState([]);
  const [activeJuri, setActiveJuri] = useState('0');
  const [tenementType, setTenementType] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [loading, setLoading] = useState(false);

  const jurisdictionUrl = `${constants.BACKEND_SERVER_BASEURL}/fileUpload/getJurisdictions`
  const {isExpanded}=useContext(NavbarContext)

  const switchToGridView = () => {
    setCurrentView('grid');
  };

  const switchToListView = () => {
    setCurrentView('list');
  };

  useEffect(() => {
    const getJurisdictions = async () => {
      try {
        setLoading(true);
        const jurisdictionsResponse = await axios.get(jurisdictionUrl);
        setAllJurisdictions(jurisdictionsResponse.data);
      } catch (error) {
        console.error('Error fetching jurisdictions:', error);
      } finally {
        setLoading(false);
      }
    };

    getJurisdictions();
  }, [jurisdictionUrl])

  const handleActiveJuri = (e) => {
    setActiveJuri(e.target.value)
  }

  const handleTenementType = (e) => {
    setTenementType(e.target.value)
  }

  const handleDocumentName = (e) => {
    setDocumentName(e.target.value)
  }


  return (
    <div>
      <div className="container-fluid px-4 filter-bar-container">
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-4 d-flex align-items-center">
            <div className="static_heading">My Documents</div>
            <div className='upload_button_container'>
              <Link className='upload_file' to={"/FileUpload"}><button className={`uplaod-file-button ${isExpanded && 'expanded-button'}`}>Upload File</button></Link>
              </div>
          </div>

          <div className="col-xs-12 col-md-6 col-lg-8  d-flex align-items-center justify-content-end">
            <div className="col-1 m-2">
              <Form.Select className="form-select juri_dropdown" onChange={(e) => handleActiveJuri(e)}>
                <option value='0' >All</option>
                {allJurisdictions.map(each => (
                  <option key={each.juri_id} value={each.juri_id}>
                    {each.juri_name_short}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="col-2 m-2 ">
              <div className="position-relative ">
                <input
                  type="text"
                  className="form-control search_container"
                  placeholder='Tenement Type'
                  onChange={(e) => handleTenementType(e)}
                />
                {!tenementType && (
                  <CiSearch className='search_icon' />
                )}
              </div>
            </div>

            <div className="col-3 m-2 ">
              <div className="position-relative ">
                <input
                  type="text"
                  placeholder='Document Name'
                  className="form-control search_container"
                  onChange={(e) => handleDocumentName(e)}
                />
                {!documentName && (
                  <CiSearch className='search_icon' />
                )}
              </div>
            </div>

            <div className="col-auto m-2" onClick={switchToListView}>
                 <div>
                    <RxHamburgerMenu className={`my_document_icon${currentView === 'list' ? ' isActive' : ''}`}/>
                </div>
            </div>

            <div className="col-auto m-2"  onClick={switchToGridView}>
                <div >
                  <IoGrid className={`my_document_icon2 ${currentView === 'grid' ? ' isActive' : ''}`}/>
                </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className='loader-container'>
          <ColorRing visible={true} height="70" width="70" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B']}/>
        </div>
      ) : (
        (currentView === 'grid' ? <DocGridView activeJuriId={activeJuri} tenementType={tenementType} documentName={documentName} /> : <DocListView activeJuriId={activeJuri} tenementType={tenementType} documentName={documentName} />)
      )}
    </div>
    
  );
};

export default MyDocumentsBar;
