import {useContext, useState, useEffect} from 'react'
import HeaderComponent from '../components/HeaderComponent'
import NavbarContext from '../components/NavbarContext';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams, useNavigate } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import '../css/DocPreviewPage.css'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import axios from 'axios';
import DocumentPageLimitModal from '../components/PopUpCards/DocumentPageLimitModal.js';
const constants = require("../config/constants.js");



const DocPreviewPage = () => {
    const {isExpanded} = useContext(NavbarContext)
    const [numPages, setNumPages] = useState(1);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [isAllPages, setIsAllPages] = useState(false);
    const [pagePreviews, setPagePreviews] = useState([]);
    const [documentUrl, setDocumentUrl] = useState('');
    const [activePage, setActivePage] = useState('StartPage');
    const [loadingError, setLoadingError] = useState(null);
    const [startPageLoading, setStartPageLoading] = useState(true);
    const [showLimitModal, setshowLimitModal] = useState(false);
    const [totalPages, setTotalPages] = useState(null)


    const {iterationId} = useParams()


    const navigate = useNavigate()


  useEffect(() => {
    getUrl()
  }, [lastPage])

  const getUrl = async () => {
    const response = await axios.get( `${constants.BACKEND_SERVER_BASEURL}/fileUpload/getDocumentUrl/${iterationId}`)
    response.data.documentUrl.length > 0 ? setDocumentUrl(response.data.documentUrl[0].document_url) : setDocumentUrl('')

    if (response.data.documentUrl.length > 0){
      if (response.data.documentUrl[0].from_page_no === null || response.data.documentUrl[0].from_page_no === 0) {
        setStartPage(1)
        setEndPage(lastPage)
      } else {
        setStartPage(response.data.documentUrl[0].from_page_no)
        setEndPage(response.data.documentUrl[0].to_page_no)
      }
      
      
      if (response.data.documentUrl[0].from_page_no === 0 && response.data.documentUrl[0].to_page_no === 0){
        setIsAllPages(true)
      }
    }
  }

  const updatePageNumbers = async () => {
    const pageLimit = endPage - startPage

    if (pageLimit > 99) {
      return setshowLimitModal(true)
    }

    let inputData = {}
    if (isAllPages){
       inputData = {
        from_page_no: 0,
        to_page_no: 0
      }
    } else{
      inputData = {
        from_page_no: startPage,
        to_page_no: endPage
      }
    }
    

     await axios.post(`${constants.BACKEND_SERVER_BASEURL}/fileUpload/updatePageNumbers/${iterationId}`, inputData)
     navigate(`../`);
  }

  useEffect(() => {
    const generatePagePreviews = async () => {
      if (!documentUrl) {
        // Handle the case where documentUrl is undefined
        return;
      }
    
      try {
        const pdf = await pdfjs.getDocument(documentUrl).promise;
    
        if (!pdf) {
          // Handle the case where pdf is undefined
          return;
        }

        const lastPage = await pdf.numPages
        setTotalPages(lastPage)
    
        setStartPageLoading(true);
    
        const imagePromises = Array.from({ length: pdf.numPages }, (_, index) => {
          return new Promise(async (resolve) => {
            const page = await pdf.getPage(index + 1);
            const viewport = page.getViewport({ scale: 0.2 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            await page.render({ canvasContext: context, viewport }).promise;
    
            const image = new Image();
            image.src = canvas.toDataURL('image/png');
    
            image.onload = () => {
              resolve(image.src);
            };
          });
        });
    
        const previews = await Promise.all(imagePromises);
    
        setPagePreviews(previews);
        setNumPages(pdf.numPages);
        setStartPageLoading(false);
        setLastPage(lastPage)
        setLoadingError(null); // Clear any previous loading errors
      } catch (error) {
        console.error('Error loading PDF:', error);
        setLoadingError(error.message || 'Error loading PDF');
      }
    };
    

    generatePagePreviews();
  }, [documentUrl]);

  const toggleSwitch = () => {
    setIsAllPages(isAllPages => !isAllPages)
  }

  const handleStartPageClick = (pageNumber) => {
    setStartPage(pageNumber)
  };

  const handleEndPageClick = (pageNumber) => {
    setEndPage(pageNumber)
  };

  const renderCustomCanvasLayer = (canvasLayer, pageIndex) => {
    // Remove any text layer associated with the canvas
    const textLayer = canvasLayer.querySelector('.react-pdf__Page__textContent');
    if (textLayer) {
      textLayer.remove();
    }
  };

  return (
    <div className={`home-container ${isExpanded && 'expanded'}`}>
        <HeaderComponent/>
        <div className='container-fluid doc-preview-container mb-2'>
            <div className='row'> 
                             
                <div className='submit-container col-12 d-flex justify-content-between align-items-center'>
                  <Tippy
                    content={'All pages is disabled as the document is more than 100 pages'}
                    position="top"
                    animation="fade"
                    disabled={totalPages < 100} 
                    duration={200} 
                    theme="custom-theme"
                    className='custom-tool-tip-allpages'
                  >  
                  <div className="form-check form-switch d-flex align-items-center tool_tip">
                    <input className={`form-check-input toggle-button`} type="checkbox" role="switch" onClick={toggleSwitch} checked={isAllPages && true} disabled={totalPages > 100}  />
                    <span className="" >All Pages</span>
                  </div>
                </Tippy>
                    <button className={`preview-submit ${(startPage > endPage || startPage == endPage) && 'disabled-button'} ${isAllPages && 'remove-disable'}`} onClick={updatePageNumbers} >Upload</button>
                </div>
            </div> 
            <div className={`row ${isAllPages && 'shaded-area'}`}>
                <div className='col-12 d-flex viewer-container'>
                    <div className='pages-grid d-flex flex-column'>
                        <div className='d-flex justify-content-between'>
                            <p className='page-head'>Start Page</p>
                            <p className='page-number'>{startPage}</p>
                        </div>
                        <div className='preview-cards-container' onClick={() => setActivePage('StartPage')}>
                            {/* Start Page - Page Grid */}
                            {startPageLoading ? 
                              <div className='loader-container'>
                                <ColorRing visible={true} height="70" width="70" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B']}/>
                              </div> : 
                              <>
                                <div className="page-grid">
                                    {Array.from({ length: numPages }, (_, index) => (
                                    <div
                                        key={index + 1}
                                        className={`page-card ${startPage === index + 1 ? 'selected' : ''}`}
                                        onClick={() => handleStartPageClick(index + 1)}
                                    >
                                        {!startPageLoading && <img src={pagePreviews[index]} alt={`Page ${index + 1}`} title={`Page ${index + 1}`}/>}
                                    </div>
                                    ))}
                                </div>
                              </>}
                        </div>
                        <div className='d-flex justify-content-between mt-5'>
                            <p className='page-head'>End Page</p>
                            <p className='page-number'>{endPage}</p>
                        </div>
                        <div className='preview-cards-container' onClick={() => setActivePage('EndPage')}>
                          {/* End Page - Page Grid */}
                              {startPageLoading ? 
                              <div className='loader-container'>
                                <ColorRing visible={true} height="70" width="70" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B']}/>
                              </div> : 
                              <>
                                <div className="page-grid">
                                  {Array.from({ length: numPages }, (_, index) => (
                                    <div
                                        key={index + 1}
                                        className={`page-card ${endPage === index + 1 ? 'selected' : ''}`}
                                        onClick={() => handleEndPageClick(index + 1)}
                                    >
                                        <img src={pagePreviews[index]} alt={`Page ${index + 1}`} title={`Page ${index + 1}`}/>
                                    </div>
                                  ))}
                                </div>
                              </>}
                        </div>
                    </div>
                    <div className='page-preview'>
                      {activePage === 'StartPage' ? 
                        <div className="page-display">
                            <Document file={documentUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                              <Page pageNumber={startPage} renderTextLayer={false} renderCanvasLayer={(canvasLayer) => renderCustomCanvasLayer(canvasLayer, startPage)} />
                            </Document>
                        </div> : 
                        <div className="page-display">
                           <Document file={documentUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                              <Page pageNumber={endPage} renderTextLayer={false} renderCanvasLayer={(canvasLayer) => renderCustomCanvasLayer(canvasLayer, endPage)} />
                            </Document>
                        </div>}
                        
                    </div>
                </div>
            </div>
        </div>

        {showLimitModal && <DocumentPageLimitModal showModal={showLimitModal} onExit={() => setshowLimitModal(false)}/>}
    </div>
  )
}

export default DocPreviewPage