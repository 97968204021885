import '../css/home.css';
import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../config/axiosConfig';
import ProgressStatusPieGraph from '../components/ProgressStatusPieGraph';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Form from 'react-bootstrap/Form';
import Lightining from '../assets/Lightning.png';
import Arrow from '../assets/Arrow.png';
import WaterDrop from '../assets/WaterDrop.png';
import Tier from '../assets/tier.png';

const constants = require('../config/constants.js');

function findPercentage(completedDataPoints, incompleteDataPoints)
{
    var percent = (completedDataPoints/(completedDataPoints+incompleteDataPoints))*100;
    return Math.round(percent);
}

const SummaryReport = () => {
    const [totalDataPoints, setTotalDataPoints]           = useState(0);
    const [eDataPointsSummary, setEDataPointsSummary]     = useState(0);
    const [sDataPointsSummary, setSDataPointsSummary]     = useState(0);
    const [gDataPointsSummary, setGDataPointsSummary]     = useState(0);
    const { iterationId, companyId }                      = useParams();
    const [iterationName, setiterationName]               = useState('');
    const navigate                                        = useNavigate();
    const [dropDownOptions, setDropDownOptions]           = useState([]);
    const [selectedValue, setSelectedValue]               = useState('');

    useEffect(() => {  
      axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getJurisdictionsByCompanyIdAndIterationId/${iterationId}/${companyId}`)
            .then((response) => {
              const options = response.data;
              setDropDownOptions(options);
              if (options.length > 0) {
                getDataCollection(options[0].juri_id);
              }
            })
            .catch((error) => {
              console.error('error:', error);
            });
      
      axios.get(`${constants.BACKEND_SERVER_BASEURL}/iterations/getIterationDetails/${iterationId}`)
            .then((response) => {
              setiterationName(response.data[0].iteration_name)
            })
            .catch((error) => {
              console.error('error:', error);
            });
    }, [iterationId, companyId]);

    const getDataCollection = async (juriId) => {
      setSelectedValue(juriId);
      axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getSummaryReport/${iterationId}/${companyId}/${juriId}`)
        .then((response) => {
          // console.log(response)
            setTotalDataPoints(findPercentage(response.data.totalDataPointsSummary[0].non_empty_count, response.data.totalDataPointsSummary[0].empty_count));
            setEDataPointsSummary(findPercentage(response.data.eDataPointsSummary[0].non_empty_count, response.data.eDataPointsSummary[0].empty_count));
            setSDataPointsSummary(findPercentage(response.data.sDataPointsSummary[0].non_empty_count, response.data.sDataPointsSummary[0].empty_count));
            setGDataPointsSummary(findPercentage(response.data.gDataPointsSummary[0].non_empty_count, response.data.gDataPointsSummary[0].empty_count));
        })
        .catch((error) => {
            console.error('error:', error);
          });
    }

    const handleEditDataPointsButton = async() => {
        var dataPointsData = await axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getAllDataPointsByIterationIdAndCompanyId/${iterationId}/${companyId}`)
        // console.log(dataPointsData.data)
        navigate(`/Report/${iterationId}/${companyId}`);
    };
    
    const handleExportButton = async () => {
      if (selectedValue !== '') {
        try {
          const juriLevelDataPoints = await axios.get(
            `${constants.BACKEND_SERVER_BASEURL}/dataPoints/getAllDataPointsForExportByIterationIdAndCompanyIdAndJuriId/${iterationId}/${companyId}/${selectedValue}`
          );
          const data = juriLevelDataPoints.data;
    
          const worksheet = XLSX.utils.json_to_sheet(data);
    
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
          saveAs(blob, 'Data Points.xlsx');
        } catch (error) {
          console.error('Error exporting to Excel:', error);
        }
      }
    };
    
  return (
    <div className='home-container'>
        <HeaderComponent />
        <div className="container-fluid">
          <div className="row px-2">
            <div className="col-12">
              {/* <h3 className="text-center">{iterationName}</h3> */}
            </div>

            <div className='col-3'>
              <Form.Select onChange={(event) => getDataCollection(event.target.value)} className={`btn btn-light light-btn   reports_dropdown`}>
                {dropDownOptions.map(option => (
                  <option key={option.juri_id} value={option.juri_id} className={`selectOptions`}>{option.juri_name}</option>
                ))}
              </Form.Select>
            </div>
            <div className='col-9'>
              <button className={`btn btn-default  m-4 reports_export_button`} onClick={handleExportButton}>Export</button>
              <button className={`btn btn-default  m-4 reports_edit_button`} onClick={handleEditDataPointsButton}>Edit</button>
            </div>
          </div>

          <div className="row px-2">
            <div className="col-3">
              <div className='d-flex flex-column justify-content-center align-items-center all_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container d-flex justify-content-center'>
                    <img src={Lightining} alt='Lightining' className='Lightining_img align-self-center' />
                  </div>
                  <p className='report-heading1'>Overall</p>
                </div>
                <ProgressStatusPieGraph percentage={totalDataPoints} colour="#FFFFFF" backgroundColor="#5ED91A" />
                {/* <p className='report-heading5'>You are {totalDataPoints}% complaint with MiniESG</p> */}
              </div>
            </div>
            <div className="col-3">
              <div className='report-bg d-flex flex-column justify-content-center align-items-center environment_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container environment_icon_color d-flex justify-content-center'>
                    <img src={Arrow} alt='Arrow' className='arrow_img align-self-center' />
                  </div>
                  <p className='report-heading2'>Environment</p>
                </div>
                <ProgressStatusPieGraph percentage={eDataPointsSummary} colour="#FF7E86" backgroundColor="#F4F5F9" />
              </div>
            </div>
            <div className="col-3">
              <div className='report-bg d-flex flex-column justify-content-center align-items-center environment_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container social_icon_color d-flex justify-content-center'>
                    <img src={WaterDrop} alt='WaterDrop' className='waterdrop_img align-self-center' />
                  </div>
                  <p className='report-heading3'>Social</p>
                </div>
                <ProgressStatusPieGraph percentage={sDataPointsSummary} colour="#00A656" backgroundColor="#F4F5F9" />
              </div>
            </div>

            <div className="col-3 ">
              <div className='report-bg d-flex flex-column justify-content-center align-items-center environment_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container governence_icon_color d-flex justify-content-center'>
                    <img src={Tier} alt='Tier' className='Lightining_img align-self-center' />
                  </div>
                  <p className='report-heading4'>Governance</p>
                </div>
                <ProgressStatusPieGraph percentage={gDataPointsSummary} colour="#F6CC0D" backgroundColor="#F4F5F9" />
              </div>
            </div>
          </div>

        </div>
    </div>

  );
};

export default SummaryReport;
