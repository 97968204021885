import {useState} from 'react'
import { MdAdd } from 'react-icons/md'
import '../css/NuggetsNavbar.css'
function NuggetsNavbar() {
    const [activeButton, setActiveButton] = useState('nuggets-btn');

  const changeButton = (buttonId) => {
    setActiveButton(buttonId);
  };

  const [selectedFilter, setSelectedFilter] = useState('viewAll');

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };
 
  return (
    <div className="container-fluid nuggets-navbar-container">
      <div className="row  pt-5 ">
        <div className="d-flex justify-content-between nuggets_btn_container">
          <div className="d-flex">
            <button
              id="nuggets-btn"
              className={`button  ${activeButton === 'nuggets-btn' ? 'active' : ''}`}
              onClick={() => changeButton('nuggets-btn')}
            >
              Nuggets
            </button>
            <button
              id="actions-btn"
              className={`button mx-3  ${activeButton === 'actions-btn' ? 'active' : ''}`}
              onClick={() => changeButton('actions-btn')}
            >
              Actions
            </button>
            <button
              id="chat-btn"
              className={`button  ${activeButton === 'chat-btn' ? 'active' : ''}`}
              onClick={() => changeButton('chat-btn')}
            >
              Chat
            </button>
          </div>

          <div className="d-flex">
            <select
              id="filterDropdown"
              value={selectedFilter}
              onChange={handleFilterChange}  
              className="nuggets_dropdown mr-2" >
              <option value="viewAll" className="selectOptions">
                View All
              </option>
              <option value="starred" className="selectOptions">
                Starred
              </option>
              <option value="onlyHide" className="selectOptions">
                Only Hide
              </option>
            </select>

            <button className="button d-flex align-items-center actions_button mx-3">
              <MdAdd /> Actions
            </button>

            <button className="button d-flex align-items-center Nugget_btn">
              <MdAdd /> Nugget
            </button>
            
          </div>
        </div>
      </div>
</div>


  );
}

export default NuggetsNavbar;
