import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import '../css/ActionsEditPage.css';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import HeaderComponent from '../components/HeaderComponent';
import { useContext } from 'react';
import SubmissionCompleted from '../components/PopUpCards/CustomNuggetModel';
import NavbarContext from '../components/NavbarContext';
import userProfile from '../assets/Ellipse 21.png';


import StarRatings from 'react-star-ratings';

function ActionsEditPage() {
  const { isExpanded } = useContext(NavbarContext);
  const [isModalOpen , setIsModalOpen] =useState(false);

  // State variables to store input values
  const [editedActionName, setEditedActionName] = useState('');
  const [editedActionType, setEditedActionType] = useState('');
  const [editedBaseDate, setEditedBaseDate] = useState('');
  const [editedModifierRule, setEditedModifierRule] = useState('');
  const [editedRepetitionRule, setEditedRepetitionRule] = useState('');
  const [editedPhase, setEditedPhase] = useState('');
  const [editedProcess, setEditedProcess] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedTotalFeedback, setEditedTotalFeedback] = useState('');


  const location = useLocation();

  // const { actionData } = location.state || {};
  // console.log(actionData,"actionsData")
  const searchParams = new URLSearchParams(location.search);
  const actionData = JSON.parse(searchParams.get('actionData'));
  console.log('location:',actionData)


  const handleSave = async () => {
    try {
      // Make an API call to save the edited data
      const response = await axios.put(`/api/actions/${actionData.action_id}`, {
        editedActionName,
        editedActionType,
        editedBaseDate,
        editedModifierRule,
        editedRepetitionRule,
        editedPhase,
        editedProcess,
        editedDescription,
        editedTotalFeedback,
      });

      // Handle success or show a success message
      console.log('Data saved successfully!', response.data);

      // Optionally, you can show a modal or navigate to another page
      setIsModalOpen(true);
    } catch (error) {
      // Handle error or show an error message
      console.error('Error saving data:', error);
    }
  };

  useEffect(() => {
    if (actionData) {
      setEditedActionName(actionData.actionName);
      setEditedActionType(actionData.actionType);
      setEditedBaseDate(actionData.baseDate);
      setEditedModifierRule(actionData.modifierRule);
      setEditedRepetitionRule(actionData.repetitionRule);
      setEditedPhase(actionData.phase);
      setEditedProcess(actionData.process);
      setEditedDescription(actionData.description);
      setEditedTotalFeedback(actionData.totalFeedback);
    }
  }, [actionData]);


  return (
    <div className='edit_actions_container'>
      <div className={`home-container ${isExpanded && 'expanded'}`}>
      <div>
        <HeaderComponent />
        {isModalOpen && <SubmissionCompleted showModal={isModalOpen} onClose={() => setIsModalOpen(false)}/>}
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-10 mt-5  d-flex justify-content-center edit_actions_container'>
            <div className='edit_actions_card_container'>
            <div className=' d-flex justify-content-between actions_button_container'>
              <button className='actions_btn'>Back</button>
              <button className='actions_btn actions_button' onClick={handleSave}>Save</button>
            </div>
            <div className='edit_action_container'>
              <div className='mt-4 p-3 edit_action_details_container'>
                <div className=' d-flex justify-content-between edit_actions_card'>
                  <h4 className='edit_actions_heading'>Edit Action Details</h4>
                  <div className='d-flex star_rating_container d-flex align-items-center justify-content-center '>
                  {actionData && 'rating' in actionData && (
                    <StarRatings
                      rating={actionData.rating}
                      starRatedColor='orange'
                      numberOfStars={5}
                      name='rating'
                      starDimension='15px'
                      starSpacing='1px'
                      className='edit_actions_star_rating'
                    />
                  )}
                  <h4 className='click_to_go_to_nugget'>Click to go to nugget</h4>
                  </div>
                </div>
                <div className='d-flex edit_actions_input_fields pt-3'>
                  <div className="input-group">
                    <label className='edit_actions_label' htmlFor="field1">Action Name</label>
                    <textarea className='edit_actions_textarea' id="field1" value={editedActionName} onChange={(e)=> setEditedActionName(e.target.value)} rows="1"></textarea>
                  </div>
                  <div className="input-group d-flex flex-column">
                    <label className='edit_actions_label' htmlFor="field2">Action Type</label>
                    <select
                      className="actions_dropdown "  value={editedActionType} onChange={(e) => setEditedActionType(e.target.value)}>
                      <option value='Payment' className="editselectOptions">
                        Payment
                      </option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label className='edit_actions_label' htmlFor="field3">Base Date</label>
                    <textarea className='edit_actions_textarea' value={editedBaseDate} onChange={(e) => setEditedBaseDate(e.target.value)} id="field3" rows="1"></textarea>
                  </div>
                  <div className="input-group">
                    <label className='edit_actions_label' htmlFor="field4">Modifier Rule</label>
                    <textarea className='edit_actions_textarea' value={editedModifierRule} onChange={(e)=>setEditedModifierRule(e.target.value)} id="field4" rows="1"></textarea>
                  </div>
                  <div className="input-group d-flex flex-column">
                    <label className='edit_actions_label' htmlFor="field2">Repetition Rule</label>
                    <select
                      className="actions_dropdown " value={editedRepetitionRule} onChange={(e) =>setEditedModifierRule(e.target.value)}>
                      <option value="None" className="editselectOptions">
                        None
                      </option>
                    </select>
                  </div>
                  <div className="input-group d-flex flex-column">
                    <label className='edit_actions_label' htmlFor="field2">Phase</label>
                    <select
                      className="actions_dropdown " value={editedPhase} onChange={(e) =>setEditedPhase(e.target.value)} >
                      <option value="Pre-Lodged" className="editselectOptions">
                      Pre-Lodged
                      </option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label className='edit_actions_label' htmlFor="field7">Process</label>
                    <textarea className='edit_actions_textarea' value={editedProcess} onChange={(e) => setEditedProcess(e.target.value)} id="field7" rows="1"></textarea>
                  </div>
                  <div className="input-group">
                    <label className='edit_actions_label' htmlFor="field7"></label>
                  </div>
                </div>
                <div className="input-group">
                    <label className='edit_actions_label' htmlFor="field7">Description</label>
                    <textarea className='edit_actions_textarea edit_textarea' value={editedDescription} onChange={(e) => setEditedDescription(e.target.value)} id="field7" rows="2"></textarea>
                </div>
                <div className="input-group mt-3">
                    <label className='edit_actions_label' htmlFor="field7">Total Feedback</label>
                    <textarea className='edit_actions_textarea edit_textareas'value={editedTotalFeedback} onChange={(e)=> {console.log('New Value:', e.target.value);setEditedTotalFeedback(e.target.value)}} id="field7" rows="2"></textarea>
                </div>
              </div>
            </div>

            <div className='edit_action_container'>
              <div className='mt-4 p-3 edit_action_details_container'>
                <div className=' d-flex justify-content-between edit_actions_card'>
                  <h4 className='edit_actions_heading'>Comments</h4>
                  <div className='d-flex star_rating_container d-flex align-items-end justify-content-center '>
                  <h4 className='sort_by'>Sort by</h4>
                  <select id="commentSort" className=' comments_filter_dropdown'>
                    <option value="oldest">Oldest</option>
                    <option value="newest">Newest</option>
                  </select>
                  </div>
                </div>
                <hr className='edit_actions_hrline'></hr>
                <div className='comments_section'>
                  <div className='mb-3'>
                    <textarea
                      className='form-contro comment_inputbox'
                      rows='1'
                      placeholder='Add Comment'
                    ></textarea>
                    <h6 className='edit_actions_textarea'>Notify Lorem ipsum dolor sit amet consectetur. Lobortis dui orci vel risus turpis non. Amet nisl ut in scelerisque. Cursus felis viverra vitae ullamcorper nunc platea morbi in aenean. Erat risus lectus leo ut.</h6>
                  </div>

                  <div className='comment d-flex align-items-center'>
                    <div className='user_photo_container'>
                      <img src={userProfile} alt='U' className='user_photo' />
                    </div>
                    <div className='comment_details'>
                      <h6 className='username'>Esther Howard</h6>
                      <h5 className='commented_date'>July 25, 2021 2:30 PM</h5>
                      <p className='comment_content'>I guess the IA is looking good for now. Let’s get started with next process</p>
                    </div>
                  </div>

                  <div className='comment d-flex align-items-center'>
                    <div className='user_photo_container'>
                      <img src={userProfile} alt='U' className='user_photo' />
                    </div>
                    <div className='comment_details'>
                      <h6 className='username'>Esther Howard</h6>
                      <h5 className='commented_date'>July 25, 2021 2:30 PM</h5>
                      <p className='comment_content'>I guess the IA is looking good for now. Let’s get started with next process</p>
                    </div>
                  </div>

                  <div className='comment d-flex align-items-center'>
                    <div className='user_photo_container'>
                      <img src={userProfile} alt='U' className='user_photo' />
                    </div>
                    <div className='comment_details'>
                      <h6 className='username'>Esther Howard</h6>
                      <h5 className='commented_date'>July 25, 2021 2:30 PM</h5>
                      <p className='comment_content'>I guess the IA is looking good for now. Let’s get started with next process</p>
                    </div>
                  </div>
                  <div className='comment d-flex align-items-center'>
                    <div className='user_photo_container'>
                      <img src={userProfile} alt='U' className='user_photo' />
                    </div>
                    <div className='comment_details'>
                      <h6 className='username'>Esther Howard</h6>
                      <h5 className='commented_date'>July 25, 2021 2:30 PM</h5>
                      <p className='comment_content'>I guess the IA is looking good for now. Let’s get started with next process</p>
                    </div>
                  </div>
                  <button className=' load_more_comments'>Load 13 more comments</button>
                </div>
              </div>
            </div>
            
            
          </div>
          </div>
        </div>
      </div>
    </div>


    </div>
    
  )
}

export default ActionsEditPage

