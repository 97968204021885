import { useMemo } from 'react';
import { createStore } from '@react-pdf-viewer/core';

const useJumpToPagePlugin = () => {
  const store = useMemo(() => createStore(), []);

  const install = (pluginFunctions) => {
    store.update('jumpToPage', pluginFunctions.jumpToPage);
  };

  const jumpToPage = (pageIndex) => {
    const fn = store.get('jumpToPage');
    console.log('na', fn)
    if (fn) {
      fn(pageIndex);
    }
  };

  return {
    install,
    jumpToPage,
  };
};

export default useJumpToPagePlugin;
