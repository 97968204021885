import {useState, useEffect} from 'react';
import '../css/DocGridView.css';
import { Document, Page} from 'react-pdf';
import { MdDeleteOutline } from "react-icons/md";
import { pdfjs } from 'react-pdf';
import constants from '../config/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { ColorRing } from 'react-loader-spinner';
import DeleteConfirmationModal from '../components/PopUpCards/DeleteConfirmationModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


const DocGridView = ({activeJuriId, tenementType, documentName}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState();
  const [iterations, setIterations] = useState([]);
  const [activeIteration, setActiveIteration] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  const url = `${constants.BACKEND_SERVER_BASEURL}/iterations/getAllIterations`

  const navigate = useNavigate()

      useEffect(() => {
        const getIterations = async () => {
          setLoader(true)
          const response = await axios.get(url);
          if (tenementType !== '') {
            const filteredIterations = response.data.filter(item => String(item.tenement_type.toLowerCase()).includes(tenementType.toLowerCase()));
            setIterations(filteredIterations)
          } else if (documentName !== '') {
            const filteredIterations = response.data.filter(item => String(item.document_name.toLowerCase()).includes(documentName.toLowerCase()));
            setIterations(filteredIterations)
          } else if (activeJuriId !== '0') {
            const filteredIterations = response.data.filter(item => String(item.juri_id) === String(activeJuriId));
            setIterations(filteredIterations);
          }  
          else {
            setIterations(response.data);
          }
        };
        
      
        getIterations();
        setLoader(false)
      }, [activeJuriId, tenementType, documentName]);
      


      const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
      };
    

      const onDeleteButton = (event, iterationId) => {
        event.stopPropagation()
        setShowDeleteModal(true)
        setActiveIteration(iterationId)
      }

      const onCard = (iterationStatus, fromPage, toPage, iterationId) => {
        if (iterationStatus === 3){
          navigate(`/NuggetsPage/${iterationId}`)
        } else if (iterationStatus !== 3 && (fromPage === null && toPage === null) ){
          navigate(`/DocPreview/${iterationId}`)
        }
      }

      return (
        <div className="container-fluid mt-5 px-4 document_container">
          <div className="row">
          {
              loader ? 
              <div className='loader-container'>
                <ColorRing visible={true} height="70" width="70" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B']}/>
              </div>
              :
              <>
                {iterations && Array.isArray(iterations) && iterations.length === 0 ? (
                  <div className="no-iterations-message">
                    <p>No iterations available.</p>
                  </div>
                  ) : (
                    iterations.map((card, index) => (
                        <div key={index} className='grid-card'  >
                          {card && card.iteration_status !== undefined && (
                              <Tippy
                                key={index}
                                content={
                                  card.from_page_no === null && card.to_page_no === null ? (
                                    <span>Pages are not selected. Click on the document to specify the page range.</span>
                                  ) : (
                                    card.iteration_status === constants.ITERATION_STATUS.DONE ? (
                                      <span>Process Completed</span>
                                    ) : (
                                      card.iteration_status === constants.ITERATION_STATUS.PROCESSING_ITERATION ? (
                                        <span>In Progress</span>
                                      ) : (
                                        card.iteration_status === constants.ITERATION_STATUS.DATA_INSERTED_SUCCESSFULLY ? (
                                          card.is_re_run_flag === constants.ITERATION_STATUS.DATA_INSERTED_SUCCESSFULLY ?(
                                            <span>Re-Run is in progress</span>
                                          ):(
                                          <span>Waiting for process to start</span>
                                          )
                                        ) : (
                                          card.error_message
                                        )
                                      )
                                    )
                                  )
                                }
                                position="top"
                                animation="fade" 
                                duration={200} 
                                theme="custom-theme"
                                className='custom-tool-tip'
                              >
                              <div className={`card-body ${(card.from_page_no === null && card.to_page_no === null) && "loaded_card" } ${card.iteration_status !==3 ? "disable_card" : "loaded_card"}`} onClick={() => onCard(card.iteration_status, card.from_page_no, card.to_page_no, card.iteration_id)}>
                                <div className='d-flex justify-content-between'>
                                  <div className='card_headers'>
                                    <h5 className="card_title">Jurisdiction</h5>
                                    <h6 className="card_subtitle">{card.juri_name_short}</h6>
                                    <h5 className="card_text page_numbers">{card.tenement_type}</h5>
                                  </div>
                                  <div onClick={(event) => onDeleteButton(event,card.iteration_id)}><MdDeleteOutline className='delete-icon' /></div>
                                </div>
            
                                <div className={`pdf_viewer_container ${(card.from_page_no === null && card.to_page_no === null) && 'pointer_event'} ${iterations.length > 0 && String(card.iteration_status) !== '3'  ? 'shaded' : ''}`}>
                                  {card && card.document_url && (
                                  <Document file={card.document_url} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} width={200} />
                                  </Document>
                                  )}
                                </div>
            
                                <div className="card_footer">
                                <Tippy content={card.document_name} position ="top">
                                  <h5 className="card-text document_name" >{card.document_name}</h5>
                                </Tippy>
                                  {card.from_page_no === null && card.to_page_no === null ? (
                                    <h5 className="card_text page_numbers">Pages are not selected</h5>
                                  ) : card.from_page_no === 0 && card.to_page_no === 0 ? (
                                    <h5 className="card_text page_numbers">All Pages</h5>
                                  ) : <h5 className="card_text page_numbers">{`Pages ${card.from_page_no}-${card.to_page_no}`}</h5> }
                                </div>
                              </div>
                              </Tippy>
                            )}
                        </div>
                    ))
                  )}
              </>
            }   
          </div>
          {
            showDeleteModal && <DeleteConfirmationModal showModal={showDeleteModal} onExit={() => setShowDeleteModal(false)} iterationId={activeIteration}/>
          }
        </div>
      );
    };
    
    export default DocGridView;