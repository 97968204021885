import React,{ useState,useEffect, useRef } from 'react';
import { Navigate, useParams,useNavigate } from 'react-router-dom';
import '../css/NuggetsPage.css';
import HeaderComponent from '../components/HeaderComponent';
import NavbarContext from '../components/NavbarContext';
import { useContext } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import { TbEyeClosed } from 'react-icons/tb';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { FaArrowRight } from 'react-icons/fa6';
import { AiOutlineMessage } from "react-icons/ai";
import { MdAdd } from 'react-icons/md'
import { TiTick } from "react-icons/ti";
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import constants from '../config/constants';
import SubmissionCompleted from '../components/PopUpCards/CustomNuggetModel';
import ActionsPage from './ActionsPage';
import { ColorRing, ThreeDots } from 'react-loader-spinner';
import { FaPowerOff } from "react-icons/fa6";

import "@react-pdf-viewer/search/lib/styles/index.css"
import useJumpToPagePlugin from '../pages/useJumpToPagePlugin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import Tippy from '@tippyjs/react';
import { Document, Page} from 'react-pdf';
import 'tippy.js/dist/tippy.css';

// const constants = require("../config/constants.js");



function NuggetsPage() {
  const { isExpanded } = useContext(NavbarContext);
  const [nuggetItems, setnuggetItems] = useState([]);
  const [keyInfoFeedbackUpdated, setKeyInfoFeedbackUpdated] = useState({});
  const [timelineFeedbackUpdated, setTimelineFeedbackUpdated] = useState(Array(nuggetItems.length).fill(false));
  const [referencFeedbackUpdated, setReferencFeedbackUpdated] = useState(Array(nuggetItems.length).fill(false));

  const [docUrl, setDocUrl] = useState('');
  const [docFromPageNo, setdocFromPageNo] = useState('');
  const [docToPageNo, setdocToPageNo] = useState('');
  const [documentName, setDocumentName] =useState('')


  const [isModalOpen , setIsModalOpen] =useState(false);
  const [selectedFilter, setSelectedFilter] = useState('viewAll');
  const [activeButton, setActiveButton] = useState('nuggets-btn');
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [isPopupClosed, setIsPopupClosed] = useState(false);
  const [totalPages, setTotalPages] = useState('')
  const [customNuggetLoader, setCustomNuggetLoader] = useState(false)
  const [aiModelLatestVersion,setAiModelLatestVersion] = useState({});
  const [iterationInfo,setIterationInfo] = useState({});
  const [trainModelData,setTrainModelData] = useState({});
  //toastify
  const notify =  () => {
    toast.success('Submitted', {
      position: 'bottom-center',
      autoClose: 1000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      theme: 'light',
    });
  }

  // pdf layout
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  //jump to page 
  const jumpToPagePluginInstance = useJumpToPagePlugin()
  const { jumpToPage } = jumpToPagePluginInstance

  

  const {iterationId} = useParams()

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages)
  }

// Fetch data from the backend API
useEffect(() => {
  let isMounted = true;

  const fetchData = async () => {
    try {
      setLoadingFilter(true);
      const url = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getAllNuggetsByIterationId/${iterationId}`;
      const response = await axios.get(url);

      let filteredData;

      if (selectedFilter === 'starred') {
        filteredData = response.data.filter(item => item.is_stared === 1);
      } else if (selectedFilter === 'onlyHide') {
        const hideUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getHiddenNuggets/${iterationId}`;
        const hideResponse = await axios.get(hideUrl);
        filteredData = hideResponse.data.results;
      }else if (selectedFilter === 'nuggetsWithoutFeedback') {
        const emptyFedbackUrl =`${constants.BACKEND_SERVER_BASEURL}/nuggets/getEmptyFeedbackNuggets/${iterationId}`;
        const emptyFeedbackResponse = await axios.get(emptyFedbackUrl);
        filteredData =emptyFeedbackResponse.data.results;
      } else {
        filteredData = response.data;
      }

      if (isMounted) {
        setnuggetItems(filteredData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }{
      setLoadingFilter(false);
    }
  };

  fetchData();

  // Cleanup function
  return () => {
    isMounted = false;
  };
}, [selectedFilter, iterationId]);

const handleFilterChange = (event) => {
  setSelectedFilter(event.target.value);
};



 useEffect(() => {
  const getUrl = async () => {
      const docu_url = `${constants.BACKEND_SERVER_BASEURL}/fileUpload/getDocumentUrl/${iterationId}`
      const response = await axios.get(docu_url);
      setDocUrl(response.data.documentUrl[0].document_url);
      setdocFromPageNo(response.data.documentUrl[0].from_page_no);
      setdocToPageNo(response.data.documentUrl[0].to_page_no);
      setDocumentName(response.data.documentUrl[0].document_name);
      

  };
    getUrl();
  }, []);

  //AI model version details API call
    useEffect(() => {
      const getUrl = async () => {
        try{
          const aiModelVersionUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getLastExtractedVersion/${iterationId}`;
          const response = await axios.get(aiModelVersionUrl);
          setAiModelLatestVersion(response.data);
        } catch (error){
          console.error("error",error)
        }
      };
        getUrl();
      }, []);

      useEffect(() => {
        const getUrl = async () => {
          try{
            const getIterationUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getIterationInfo/${iterationId}`;
            const response = await axios.get(getIterationUrl);
            setIterationInfo(response.data.results[0]);
          } catch (error){
            console.error("error",error)
          }
        };
          getUrl();
        }, []);

      useEffect(() => {
        const getUrl = async () => {
          try{
            const getVersionUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getTrainedModelData`;
            const response = await axios.get(getVersionUrl);
            setTrainModelData(response.data.results);
          } catch (error){
            console.error("error",error)
          }
        };
          getUrl();
        }, []);
  

  //Listed Actions API call
  const handleListedActionRating = (index, actionIndex, newRating) => {
    const newState = [...nuggetItems];
    newState[index].actions[actionIndex].action_star_rating = newRating;
    setnuggetItems(newState);
  };

  //API integration for sending star rating to the backend
  const handleListedAction = (index,actionIndex) => {
    sendListedActionRating(index, actionIndex);
  };

  const sendListedActionRating = async (index, actionIndex) => {
    const apiUrl =  `${constants.BACKEND_SERVER_BASEURL}/nuggets/addActionRating`;
    const nugget_id = nuggetItems[index].nugget_id;
  
    try {
      const response = await axios.post(apiUrl, {
        nugget_id,
        action_id: nuggetItems[index].actions[actionIndex].action_id,
        action_star_rating: nuggetItems[index].actions[actionIndex].action_star_rating,
      });
  
      // console.log('Feedback and Rating sent to backend:', response.data);
    } catch (error) {
      console.error('Error sending feedback and rating to backend:', error);
    }
  };


  const handleKeyInfoRatingChange = (index, newRating, keyInfoIndex) => {
    const newState = [...nuggetItems];
    newState[index].key_infos[keyInfoIndex].key_info_star_rating = newRating;
    setnuggetItems(newState);
  };
  
  const handleKeyInfoTextareaChange = (index, event, keyIndex) => {
    const newState = [...nuggetItems];
    newState[index].key_infos[keyIndex].key_info_feedback = event.target.value;
    // setnuggetItems(newState);
    setKeyInfoFeedbackUpdated((prevValue) => ({
      ...prevValue,
      [`${index}-${keyIndex}`]: true,
    }));
  };
  

  const handleRatingTimeLine = (index, newRating) => {
    const newState = [...nuggetItems];
    newState[index].timeline_star_rating = newRating;
    setnuggetItems(newState);
  };

  const handleTextareaTimeLine = (index, event) => {
    const newState = [...nuggetItems];
    newState[index].timeline_feedback = event.target.value;
    // setnuggetItems(newState);
    setTimelineFeedbackUpdated(prevValue => {
      const newState = [...prevValue];
      newState[index] = true;
      return newState;
  });
  };

  const handleRatingReferences = (index, newRating) => {
    const newState = [...nuggetItems];
    newState[index].reference_star_rating = newRating;
    setnuggetItems(newState);
  };


  const handleTextareaReferences = (index, event) => {
    const newState = [...nuggetItems];
    newState[index].reference_feedback = event.target.value;
    // setnuggetItems(newState);
    setReferencFeedbackUpdated(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
  });
  };

  

  const handleStarClick = async (index) => {
    const newState = [...nuggetItems];
    newState[index].is_stared = !newState[index].is_stared;
    setnuggetItems(newState);

    // Add API call to update isStared status on the backend
  const apiUrl =  `${constants.BACKEND_SERVER_BASEURL}/nuggets/starNugget`;
  const nugget_id = newState[index].nugget_id;
  const is_stared = newState[index].is_stared ? 1 : 0;

  try {
    const response = await axios.post(apiUrl, {
      nugget_id,
      is_stared,
    });

    // console.log('Star status sent to backend:', response.data);
  } catch (error) {
    console.error('Error updating star status on the backend:', error);
  }
  };
  


  const handleEyeClick = async(index) => {
    const newState = [...nuggetItems];
    
    if(
      newState[index].is_hide_flag === 0
    ){
      newState[index].is_hide_flag = 1;
    }
    else if(
      newState[index].is_hide_flag === 1
    ){
      newState[index].is_hide_flag = 0;
    }
    setnuggetItems(newState);

    const apiUrl =  `${constants.BACKEND_SERVER_BASEURL}/nuggets/hideNugget`;
  const nugget_id = newState[index].nugget_id;
  const is_hide_flag = newState[index].is_hide_flag;

  try {
    const response = await axios.post(apiUrl, {
      nugget_id,
      is_hide_flag,
    });

    // console.log('text status sent to backend:', response.data);
  } catch (error) {
    console.error('Error updating text status on the backend:', error);
  }
  };

  

  //API integration for sending feedback and star rating to the backend
    const handleSubmit = (index, keyInfoIndex) => {
      if (keyInfoFeedbackUpdated[`${index}-${keyInfoIndex}`]) {
          sendFeedbackAndRatingToBackend(index, keyInfoIndex);
          setKeyInfoFeedbackUpdated(prevState => {
              const newState = { ...prevState };
              delete newState[`${index}-${keyInfoIndex}`];
              return newState;
          });
      }
  };

  const sendFeedbackAndRatingToBackend = async (index,keyInfoIndex) => {
    const apiUrl =  `${constants.BACKEND_SERVER_BASEURL}/nuggets/addKeyInfoFeedback`;
    const nugget_id = nuggetItems[index].nugget_id;
    if (keyInfoIndex !== undefined) {
      try {
        if (nuggetItems[index].key_infos[keyInfoIndex].key_info_feedback.trim() !== ""){
          const response = await axios.post(apiUrl, {
            nugget_id,
            key_info_id: nuggetItems[index].key_infos[keyInfoIndex].key_info_id,
            key_info_feedback: nuggetItems[index].key_infos[keyInfoIndex].key_info_feedback,
            key_info_star_rating: nuggetItems[index].key_infos[keyInfoIndex].key_info_star_rating,
          });
          notify();
        }
        
        
        // console.log('Feedback and Rating sent to backend:', response.data);
      } catch (error) {
        console.error('Error sending feedback and rating to backend:', error);
      }
    }
  };

  //API integration for sending timeline feedback and star rating to the backend
  const handleTimelineFeedback = (index) => {
    if(nuggetItems[index].timeline_feedback !== null && nuggetItems[index].timeline_feedback !== '' && timelineFeedbackUpdated[index]) {
      sendFeedbackAndRatingOfTimelines(index);
      setTimelineFeedbackUpdated(prevValue => {
        const newState =[...prevValue];
        newState[index]=false;
        return newState;
      })
    }
    
  };

  const sendFeedbackAndRatingOfTimelines = async (index) => {
    const apiUrl =  `${constants.BACKEND_SERVER_BASEURL}/nuggets/addTimeline`;
    const nugget_id = nuggetItems[index].nugget_id;
    const timelineFeedback = nuggetItems[index].timeline_feedback.trim();
    try {
      if (nuggetItems[index].timeline_feedback.trim() !== ""){
      const response = await axios.post(apiUrl, {
        nugget_id,
        timeline_feedback: timelineFeedback,
        timeline_star_rating: nuggetItems[index].timeline_star_rating,
      });
      notify();
    }
      
      // console.log('Feedback and Rating sent to backend:', response.data);
    } catch (error) {
      console.error('Error sending feedback and rating to backend:', error);
    }
  };
    
  //API integration for sending references feedback and star rating to the backend
  const handleReferencesFeedback = (index) => {
    if (referencFeedbackUpdated[index]) {
      sendFeedbackAndRatingOfReferences(index);
      setReferencFeedbackUpdated(prevState => {
          const newState = [...prevState];
          newState[index] = false;
          return newState;
      });
  }
  };

  const sendFeedbackAndRatingOfReferences = async (index) => {
    const apiUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/addReference`;
    const nugget_id = nuggetItems[index].nugget_id;
    const referenceFeedback = nuggetItems[index].reference_feedback === '' ? null : nuggetItems[index].reference_feedback.trim();
    try {
      if (nuggetItems[index].reference_feedback.trim() !== ""){
      const response = await axios.post(apiUrl, {
        nugget_id,
        reference_feedback: referenceFeedback,
        reference_star_rating: nuggetItems[index].reference_star_rating,
      });
      notify();
    }
      
      // console.log('Feedback and Rating sent to backend:', response.data);
    } catch (error) {
      console.error('Error sending feedback and rating to backend:', error);
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const getData = async () => {
      const nuggetsUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getAllNuggetsByIterationId/${iterationId}`
      const nuggetsReponse = await axios.get(nuggetsUrl)
      const nuggetData = nuggetsReponse.data 
      setnuggetItems(nuggetData)
      setCustomNuggetLoader(false)
      setTimeout(() => {
        scrollToBottom()
      },1)
      setIsPopupClosed(false)
    }
    if (isPopupClosed){
      getData()
      setCustomNuggetLoader(true)
    }
  }, [isPopupClosed])
  
  

  // useEffect(()=>{
  //   if(localStorage?.getItem("active_button")===undefined){
  //     setActiveButton(activeButton)
  //   }
  //   else{
  //     setActiveButton(localStorage?.getItem("active_button"))
  //   }
  // },[activeButton])


  const changeButton = (buttonId) => {
    // localStorage.setItem("active_button",buttonId)
    setActiveButton(buttonId);
  };

  const onNuggetButton = () => {
    setIsModalOpen(true)
  }

  const Navigate = useNavigate()

  const handleReRun = async () => {
    
    try {
      const reRunUrl = `${constants.BACKEND_SERVER_BASEURL}/iterations/reRunIteration/${iterationId}`
      const response = await axios.get(reRunUrl);
      if (response.status === 200) {
        Navigate('/');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  return (
    <div className={`home-container ${isExpanded ? 'expanded' : ''}`}>
      <div>
        <HeaderComponent />
        {isModalOpen && (
          <SubmissionCompleted
            iterationId={iterationId}
            showModal={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            setIsPopupClosed={setIsPopupClosed}
          />
        )}
      </div>
      {customNuggetLoader && <div className='custom-nugget-loader'><ThreeDots
        visible={true}
        height="70"
        width="70"
        color="#CB5B2B"
        radius="8"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        /> </div>} <div className='container-fluid nuggets_container'>
        <div className="row">
          <div className='col-5 pdf_container'>
            <div className='pdf-container'>
              {docUrl && (
                <>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                    <div
                      className="rpv-core__viewer"
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'center',
                          backgroundColor: '#eeeeee',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                          padding: '2px',
                        }}
                      >
                        <Toolbar>
                          {(props) => {
                            const {
                              CurrentPageInput,
                              Download,
                              EnterFullScreen,
                              GoToNextPage,
                              GoToPreviousPage,
                              NumberOfPages,
                              Print,
                              ShowSearchPopover,
                              Zoom,
                              ZoomIn,
                              ZoomOut,
                            } = props;
                            return (
                              <>
                                <div style={{ padding: '2px 2px', fontSize: "12px" }}>
                                  <ShowSearchPopover />
                                </div>
                                <div style={{ padding: '0px 0px', fontSize: "12px" }}>
                                  <Tippy content={documentName} position="bottom">
                                    <p style={{ padding: '0px', margin: '0px', fontWeight: 'bold', width: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{documentName}</p>
                                  </Tippy>
                                </div>
                                <div style={{ padding: '0px 2px', fontSize: "12px" }}>
                                  <ZoomOut />
                                </div>
                                <div style={{ padding: '0px 0px', fontSize: "14px" }}>
                                  <Zoom />
                                </div>
                                <div style={{ padding: '0px 0px', fontSize: "12px" }}>
                                  <ZoomIn />
                                </div>
                                <div style={{ padding: '0px 0px', marginLeft: 'auto', fontSize: "12px" }}>
                                  <GoToPreviousPage />
                                </div>
                                <div style={{ padding: '0px 0px', minWidth: '40px',  width: '6%',fontSize: "12px" }}>
                                  <CurrentPageInput />
                                </div>
                                <div style={{ padding: '0px 2px', width: '30px',fontSize: "11px" }}>
                                  / <NumberOfPages />
                                </div>
                                <div style={{ padding: '0px 0px', fontSize: "12px" }}>
                                  <GoToNextPage />
                                </div>
                                <div style={{ padding: '0px 0px', marginLeft: 'auto', fontSize: "12px" }}>
                                  <EnterFullScreen />
                                </div>
                                <div style={{ padding: '0px 0px', fontSize: "12px" }}>
                                  <Download />
                                </div>
                                <div style={{ padding: '0px 0px', fontSize: "12px" }}>
                                  <Print />
                                </div>
                              </>
                            );
                          }}
                        </Toolbar>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          overflow: 'hidden',
                        }}
                      >
                        <Viewer fileUrl={docUrl} plugins={[toolbarPluginInstance, jumpToPagePluginInstance]} />
                      </div>
                    </div>
                  </Worker>
                </>
              )}
            </div>
          </div>
          <div className='col-7 rightside_container'>
            <div className="container-fluid nugget-cards-container">
              <div className="row  pt-2 fixed-container">
                <div className='d-flex justify-content-between'>
                  <p className='px-0 nuggets-info-text'>{`Nuggets Generated from Selected Pages ${(docFromPageNo === constants.FLAG.DISABLE && docToPageNo === constants.FLAG.DISABLE ? `${constants.FLAG.ENABLE}` : docFromPageNo)}-${(docFromPageNo === constants.FLAG.DISABLE && docToPageNo === constants.FLAG.DISABLE ? totalPages : docToPageNo)}`}</p>
                  <p className='last_extracted_model'>Last Extracted with Model {aiModelLatestVersion?.last_extracted_model_version}. This is the latest model {trainModelData?.current_model_version}</p>
                </div>
                <div className="col-12 d-flex justify-content-between nuggets_btn_container">
                  <div className="d-flex">
                    <button
                      id="nuggets-btn"
                      className={`button ${activeButton === 'nuggets-btn' ? 'active' : ''}`}
                      onClick={() => changeButton('nuggets-btn')}
                    >
                      Nuggets
                    </button>
                    <button
                      id="actions-btn"
                      disabled
                      className={`button disable mx-3 ${activeButton === 'actions-btn' ? 'active' : ''}`}
                      onClick={() => changeButton('actions-btn')}
                    >
                      Actions
                    </button>
                    <button
                      id="chat-btn"
                      disabled
                      className={`button disable ${activeButton === 'chat-btn' ? 'active' : ''}`}
                      onClick={() => changeButton('chat-btn')}
                    >
                      Chat
                    </button>
                  </div>
  
                  <div className="d-flex">
                    <select
                      id="filterDropdown"
                      value={selectedFilter}
                      onChange={handleFilterChange}
                      className="nuggets_dropdown mr-2" >
                      <option value="viewAll" className="select_options">
                        View All
                      </option>
                      <option value="starred" className="select_options">
                        Starred
                      </option>
                      <option value="onlyHide" className="select_options">
                        Only Hide
                      </option>
                      <option value="nuggetsWithoutFeedback" className="select_options">
                        Nuggets Without Feedback
                      </option>
                    </select>
  
                    <>
                      <button className="button d-flex align-items-center actions_button mx-3 disable" disabled>
                        <MdAdd /> Actions
                      </button>
  
                      <button className="button d-flex align-items-center Nugget_btn" onClick={onNuggetButton}>
                        <MdAdd /> Nugget
                      </button>
                      <FaPowerOff onClick={handleReRun} className={`re_run_button ${iterationInfo.re_run_iteration_id === null ? 're_run_button' : "re_run_button_disable"}`}/>
                    </>
                  </div>
                </div>
              </div>
            </div>
            {activeButton === 'nuggets-btn' && (
              <>
                {loadingFilter ? (
                  <div className='loader-container'>
                    <ColorRing visible={true} height="70" width="70" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B']} />
                  </div>
                ) : (
                  (nuggetItems.length > 0 ?<div>
                    {nuggetItems.map((nuggetItem, index) => (
                      <Tippy
                            key={index}
                            content={
                              (nuggetItem.timeline && nuggetItem.reference && nuggetItem.key_infos.length > 0) ? <span>Prcoess Completed</span> : <span>In Progress</span>
                            }
                            position="top"
                            animation="fade" 
                            duration={200} 
                            theme="custom-theme"
                            disabled={nuggetItem.is_custom_nugget === constants.FLAG.DISABLE}
                            className='custom-tool-tip'
                          >
                      <div className='nugget-card' key={index} data={nuggetItem}>
                        <div className='d-flex m-3 '>
                          <div className="col-4 bg-white">
                            <div className="card-container py-3">
                              <div className="card-items d-flex justify-content-between">
                                <div className='d-flex justify-content-center'>
                                  <h5 className='extract_heading'>Extract </h5>
                                  {Number(nuggetItems[index].is_stared) === 1 ? (
                                    <IoStar className={`star_icon ${nuggetItems[index].is_stared === 1 ? 'stared' : ''} star_color`} onClick={() => handleStarClick(index)} />
                                  ) : (
                                    <IoStarOutline className={`star_icon ${nuggetItems[index].is_stared === 0 ? 'stared' : ''}`} onClick={() => handleStarClick(index)} />
                                  )}
                                  {Number(nuggetItems[index].is_hide_flag) === 0 ? (
                                    <IoEyeOutline className={`eye_icon ${nuggetItems[index].is_hide_flag === 0 ? 'visible' : ''}`} onClick={() => handleEyeClick(index)} />
                                  ) : (
                                    <TbEyeClosed className={`eye_icon ${nuggetItems[index].is_hide_flag === 1 ? 'visible' : ''}`} onClick={() => handleEyeClick(index)} />
                                  )}
                                </div>
                                <div className='d-flex align-items-center'>
                                  <BsFillLightningChargeFill className='eye_icon icons_right' />
                                  {/* <span className='number_icon' onClick={() => jumpToPage(Number(nuggetItem.page_no) - 1)}>
                                    {nuggetItem.page_no}
                                  </span> */}
                                  <FaArrowRight className='eye_icon icons_right' onClick={() => jumpToPage(Number(nuggetItem.page_no) + Number(docFromPageNo) - 1)}/>
                                </div>
                              </div>
                              <p className='extract_headings'>{nuggetItem.extract_heading}</p>
                              <p className='extract_paragraph_content' onClick={() => jumpToPage(Number(nuggetItem.page_no) + Number(docFromPageNo) - 1)}>
                                {nuggetItem.extract}
                                
                              </p>
                            </div>
                          </div>
                          <div className='col-5 bg-white'>
                            <div className='middle_container p-3'>
                              {nuggetItem.key_infos.length > 0 && (
                                <h3 className="reference_head">Key Info</h3>
                              )}
                              {nuggetItem.key_infos && nuggetItem.key_infos.map((keyInfo, keyIndex) => (
                                <div key={`key_info_${keyIndex}`}>
                                  <div className='star_rating d-flex flex-column justify-content-between'>
                                    <div className="description-container">
                                      <p className="description">{keyInfo.key_info}</p>
                                    </div>
                                    <div className='star_rating_container align-self-end mb-3'>
                                      <button 
                                      // onClick={()=>handleKeyInfoStarRating(index, keyIndex)} 
                                      className='star_rating_button'>
                                      <StarRatings
                                        rating={keyInfo.key_info_star_rating !== null ? keyInfo.key_info_star_rating : 0}
                                        starRatedColor="orange"
                                        changeRating={(newRating) => handleKeyInfoRatingChange(index, newRating, keyIndex)}
                                        numberOfStars={5}
                                        name={`key_info_star_rating-${index}`}
                                        starDimension="18px"
                                      />
                                      </button>
                                    </div>
                                  </div>
                                  <div className='feedback_container d-flex justify-content-between'>
                                    <div><p className={` feedback_heading ${keyInfo.key_info_feedback === '' || keyInfo.key_info_feedback === null ? '' : 'non_empty_feedback_heading'}`}>Desired Extract {(keyInfo.key_info_feedback !== '' && keyInfo.key_info_feedback !== null) && (!keyInfoFeedbackUpdated[`${index}-${keyIndex}`] && nuggetItems[index].key_infos[keyIndex].key_info_feedback.trim() !== "") && <TiTick className='tick_mark' />}</p></div>
                                    <div className=''><p className='submit_btn' onClick={() => handleSubmit(index, keyIndex)}>Submit</p></div>
                                  </div>
                                  <div className='textarea_container'>
                                    <textarea rows="3" id={nuggetItem.nugget_id}
                                      className={`extract_textarea ${keyInfo.key_info_feedback === "" || keyInfo.key_info_feedback === null ? "" : "non_empty_feedback"} `}
                                      value={keyInfo.key_info_feedback !== null ? keyInfo.key_info_feedback : ''}
                                      onChange={(event) => handleKeyInfoTextareaChange(index, event, keyIndex)}>
                                    </textarea>
                                  </div>
                                  <hr className="separator_line" />
                                </div>
                              ))}
                            </div>
                            { nuggetItem.timeline  && (
                              <div className={`middle_container p-3 ${nuggetItem.key_infos.length === 0 ? 'timelines_container' : ''}` }>
                                <h3 className="reference_head">Timelines</h3>
                                <div className='star_rating d-flex flex-column justify-content-between timelines_container'>
                                  <div><p className="description">{nuggetItem.timeline}</p></div>
                                  <div className='star_rating_container align-self-end'>
                                  <button 
                                  // onClick={()=>handleTimelineStarRating(index)} 
                                  className='star_rating_button'>
                                    <StarRatings
                                      rating={nuggetItems[index].timeline_star_rating !== null ? nuggetItems[index].timeline_star_rating : 0}
                                      starRatedColor="orange"
                                      changeRating={(newRating) => handleRatingTimeLine(index, newRating)}
                                      numberOfStars={5}
                                      name={`timeline_star_rating-${index}`}
                                      starDimension="18px"
                                    />
                                    </button>
                                  </div>
                                </div>
                                <div className='feedback_container d-flex justify-content-between'>
                                  <div><p className={`feedback_heading ${nuggetItems[index].timeline_feedback === null || nuggetItems[index].timeline_feedback === '' ? '' : 'non_empty_feedback_heading'}`}>Desired Extract {nuggetItems[index].timeline_feedback !== null && nuggetItems[index].timeline_feedback !== '' && !timelineFeedbackUpdated[index] && nuggetItems[index].timeline_feedback.trim() !== "" && <TiTick className='tick_mark' />}</p>
                                  </div>
                                  <div className=''><p className='train_btn' onClick={() => handleTimelineFeedback(index)}>Submit</p></div>
                                </div>
                                <div className='textarea_container'>
                                  <textarea rows="3"
                                    className={`extract_textarea ${nuggetItems[index].timeline_feedback === null || nuggetItems[index].timeline_feedback == '' ? "" : "non_empty_feedback"} `}
                                    value={nuggetItems[index].timeline_feedback !== null ? nuggetItems[index].timeline_feedback : ''}
                                    onChange={(event) => handleTextareaTimeLine(index, event)}>
                                  </textarea>
                                </div>
                                <hr className="separator_line" />
                              </div>
                            )}
                            { nuggetItem.reference && (
                              <div className={`middle_container reference_container p-3 ${nuggetItem.key_infos.length === 0 ? 'reference_card_container' : ''}` }> 
                                <h3 className="reference_head">References</h3>
                                <div className='star_rating d-flex flex-column justify-content-between references_card'>
                                  <div><p className="description">{nuggetItem.reference}</p></div>
                                  <div className='star_rating_container align-self-end'>
                                    <button 
                                    // onClick={()=>handleReferenceStarRating(index)} 
                                    className='star_rating_button'>
                                    <StarRatings
                                      rating={nuggetItems[index].reference_star_rating !== null ? nuggetItems[index].reference_star_rating : 0}
                                      starRatedColor="orange"
                                      changeRating={(newRating) => handleRatingReferences(index, newRating)}
                                      numberOfStars={5}
                                      name={`reference_star_rating-${index}`}
                                      starDimension="18px"
                                    />
                                    </button>
                                  </div>
                                </div>
                              <div className='feedback_container d-flex justify-content-between'>
                                <div><p className={`feedback_heading ${nuggetItems[index].reference_feedback === null || nuggetItems[index].reference_feedback === '' ? '' : 'non_empty_feedback_heading'}`}>Desired Extract {nuggetItems[index].reference_feedback !== null && nuggetItems[index].reference_feedback !== '' && !referencFeedbackUpdated[index] && nuggetItems[index].reference_feedback.trim() !== "" && <TiTick className='tick_mark' />}</p></div>
                                <div className=''><p className='train_btn' onClick={() => handleReferencesFeedback(index)}>Submit</p></div>
                              </div>
                              <div className='textarea_container'>
                                <textarea rows="3"
                                  className={`extract_textarea ${nuggetItems[index].reference_feedback === null || nuggetItems[index].reference_feedback == '' ? "" : "non_empty_feedback"} `}
                                  value={nuggetItems[index].reference_feedback !== null ? nuggetItems[index].reference_feedback : ''}
                                  onChange={(event) => handleTextareaReferences(index, event)}>
                                </textarea>
                              </div>
                              </div>
                            )}
                          </div>
                          <div className="col-3 my-3 listed_actions_container p-3 d-flex flex-column justify-content-between">
                          <div className='d-flex flex-column listed_actions_card'>
                            <h4 className='listed_actions_heading'>Listed Actions</h4>
                            {nuggetItem.actions && nuggetItem.actions.map((action, actionIndex) => (
                              <div key={`action_${action.action_id}`}>
                                  <p className='listed_actions_paragraph'>{action.action}</p>
                                  <div className='d-flex justify-content-between star_rating_container'>
                                        <button onClick={()=>handleListedAction(index,actionIndex)} className='star_rating_button'>
                                          <StarRatings
                                            rating={action.action_star_rating !== null ? action.action_star_rating :0}
                                            starRatedColor="orange"
                                            changeRating={(newRating) => handleListedActionRating(index,actionIndex, newRating)}
                                            numberOfStars={5}
                                            name={`action_star_rating-${index}`}
                                            starDimension="12px" 
                                          />
                                        </button>
                                        {/* <AiOutlineMessage className='message_icon mt-2' /> */}
                                  </div>
                                  {actionIndex !== nuggetItem.actions.length - 1 && <hr className=" actions_line_separator" />}

                              </div>
                            ))}
                          </div>
                            <div className='d-flex flex-column align-self-center hr_line'>
                              <button disabled className="button1 disable d-flex align-items-center justify-content-center add_action_btn">
                                <MdAdd /> Add Action
                              </button>
                            </div>
                          </div>
                        </div>
                        <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef}></div>
                      </div>
                      </Tippy>
                    ))}
                    
                  </div>
                  : <div className='empty_error_message'>No Data found</div>)
                  
                )}
              </>
            )}
            {/* {activeButton === 'actions-btn' && (
              <ActionsPage />
            )} */}
          </div>
        </div>       
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {docUrl && (
        <Document file={docUrl} onLoadSuccess={onDocumentLoadSuccess}> 
        </Document>
        )}
    </div>
  )
  
}

export default NuggetsPage;
