import {useContext} from 'react'
import '../css/SignupNavbar.css';
import { NavLink,Link} from 'react-router-dom'
import NavbarContext from './NavbarContext';
// import { useNavigate } from 'react-router-dom';

export default function SignupNavbar() {
  const {isLoginPage, setIsLoginPage} = useContext(NavbarContext)
  const navHead=isLoginPage ? "Don't Have An Account" : "Already Have An Account?"
  const navStatus=isLoginPage ? "Sign up" : "Login"

  // const navigate = useNavigate()

  const toggleLogin = () => {
      setIsLoginPage(prev => !isLoginPage)
  }

  return (
    
       <nav className='signup_navbar'>
        <div className='left_side_elements'>
            <p className='login_text'>{navHead}</p>
            {navStatus === 'Sign up' ? <Link to='/Signup' className='login_link'><span onClick={toggleLogin}>{navStatus}</span></Link> : <Link to='/Login' className='login_link'><span className='margin_bottom' onClick={toggleLogin}>{navStatus} </span></Link>}
            
            
        </div>
        
        <ul>
            <li><NavLink to='/need_help_page'>Need help?</NavLink></li>
            <li><NavLink to='/terms_page'>Terms of use</NavLink></li>
            <li><NavLink to='/privacy_policy'>Privacy Policy</NavLink></li>
        </ul>
    </nav>
    
  )
}