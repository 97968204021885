import '../css/home.css';
import React, { useState, useEffect } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import DataCollection from '../components/DataCollection';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../config/axiosConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AiOutlinePlus} from 'react-icons/ai'
import Form from 'react-bootstrap/Form';


const constants = require('../config/constants.js');

const Report = () =>  {
  const navigate      = useNavigate();
  var groupedData     = {};
  const { iterationId, companyId }                  = useParams();
  const [groupedCollectionData, setgroupedData]     = useState([]);
  const [dropDownOptions, setDropDownOptions]       = useState([]);
  const [iterationName, setiterationName]           = useState('');
  const [selectedJuri, setSelectedJuri]             = useState(1);


  useEffect(() => {  
    axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getJurisdictionsByCompanyIdAndIterationId/${iterationId}/${companyId}`)
          .then((response) => {
            const options = response.data;
            setDropDownOptions(options);
            if (options.length > 0) {
              getDataCollection(options[0].juri_id);
            }
          })
          .catch((error) => {
            console.error('error:', error);
          });
    axios.get(`${constants.BACKEND_SERVER_BASEURL}/iterations/getIterationDetails/${iterationId}`)
          .then((response) => {
            setiterationName(response.data[0].iteration_name)
          })
          .catch((error) => {
            console.error('error:', error);
          });
  }, [iterationId, companyId]);

  const getDataCollection = async (juriId) => {
    groupedData = {};
    var juriLevelDataPoints =  await axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getAllDataPointsByIterationIdAndCompanyIdAndJuriId/${iterationId}/${companyId}/${juriId}`);
    var responseData = juriLevelDataPoints.data;

    responseData.forEach(item => {
      if (!groupedData[item.category]) {
        groupedData[item.category] = {};
      }
      if (!groupedData[item.category][item.bucket]) {
        groupedData[item.category][item.bucket] = [];
      }
      const exists = groupedData[item.category][item.bucket].some(existingItem => existingItem.data_point_id === item.data_point_id);
      if (!exists) {
        groupedData[item.category][item.bucket].push(item);
      }
    });
    setgroupedData(groupedData);
    setSelectedJuri(juriId)

  }

  

  const handleSummaryReportButton = () => {
    navigate(`/SummaryReport/${iterationId}/${companyId}`);
  };



  return (
    <div className='home-container'>
        <HeaderComponent/>
        <div className='container-fluid'>
          <div className='row px-2'>
            <div className="col-12">
              <h3 className="text-center">{iterationName}</h3>
            </div>
            
              <div className='col-3'>
                <Form.Select onChange={(event) => getDataCollection(event.target.value)} className='select-framework'>
                  {dropDownOptions.map(option => <option key={option.juri_id} value={option.juri_id} >{option.juri_name}</option>)}
                </Form.Select>
              </div>
              <div className='col-9 d-flex justify-content-end align-items-center'>
                <button className={`edit-page-button`}><span><AiOutlinePlus /></span> Upload Documents</button>
                <button className={`edit-page-button generate-button`} onClick={handleSummaryReportButton}>Generate Reports</button>
              </div>
            
          </div>
          
        </div>
        <div></div>
        <DataCollection groupedCollectionData={groupedCollectionData} selectedJuri={selectedJuri} />
      </div>
  );
}

export default Report;