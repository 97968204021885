
import HeaderComponent from '../components/HeaderComponent';
import FileUpload from '../components/FileUpload';
import PopupModal from '../components/PopUpCards/PopupModal';
import {useState, useContext } from 'react';
import NavbarContext from '../components/NavbarContext';



export default function UploadPage() {
  const {isExpanded} = useContext(NavbarContext)
  const [userCompanies ,setUserCompanies]=useState(0);

  // Component code here
  return (
        <div className={`home-container ${isExpanded && 'expanded'}`}>
          <HeaderComponent/>
          <div>
            <FileUpload/>
          </div>
          <div>
            {userCompanies.length < 1 ? <PopupModal/> : null}
          </div>
          <div></div>
        </div>
  );
}