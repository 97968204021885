import {useState} from 'react'
import WelcomeCard from './WelcomeCard';
import LetsGetStarted from './LetsGetStarted';
import FrameWorkInterested from './FrameWorkInterested';
import PreviewOfTheProcess from './PreviewOfTheProcess';
import '../../css/PopUpCardsCSS/PopupModal.css';

export default function PopupModal() {
  const [welcomeCard, setWelcomeCard] = useState(true);
  const [letsGetStarted, setLetsGetStarted] = useState(false);
  const [frameWorkInterested, setFrameWorkInterested] = useState(false);
  const [previewOfTheProcess, setPreviewOfTheProcess] = useState(false);
  const [companyId,setCompanyId]=useState('');

  const nextWelcomeCard = () => {
    setWelcomeCard(false);
    setLetsGetStarted(true);
  };

  const nextLetsGetStarted = () => {
    setLetsGetStarted(false);
    setFrameWorkInterested(true);
  };

  const nextFrameWorkInterested = () => {
    setFrameWorkInterested(false);
    setPreviewOfTheProcess(true);
  };

  const backToLetsGetStarted = () => {
    setLetsGetStarted(true)
    setFrameWorkInterested(false)
  }
  const backToFrameworksIntersted = () => {
    setFrameWorkInterested(true)
    setPreviewOfTheProcess(false)
  }

  const exitPopUp=()=>{
    setPreviewOfTheProcess(!previewOfTheProcess)
  }
  return (
    <div>
      <div className="modal_container">
      <WelcomeCard
        showModal={welcomeCard}
        onAction={nextWelcomeCard}
      />
      <LetsGetStarted
        showModal={letsGetStarted}
        onAction={nextLetsGetStarted}
        setCompanyId={setCompanyId}
      />
      <FrameWorkInterested
        showModal={frameWorkInterested}
        onAction={ nextFrameWorkInterested}
        onBack={backToLetsGetStarted}
        companyId={companyId}
      />
      <PreviewOfTheProcess
        showModal={previewOfTheProcess}
        onAction={ ()=>setPreviewOfTheProcess(false)}
        onBack={backToFrameworksIntersted}
        onExit={exitPopUp}
      />
    </div>
    </div>
  )
}

