import React, { useState } from 'react';
import '../../css/PopUpCardsCSS/FrameWorkInterested.css';
import environment from '../../assets/frameworkIntersted.png';
import { FRAMEWORK_IDS } from '../../config/constants';
import axios from 'axios';
import constants from '../../config/constants';

export default function FrameWorkInterested({ showModal, onAction,companyId}) {
  const checkboxIdMap = {
    csrdCheckbox: FRAMEWORK_IDS.CSRD_CHECKBOX,
    brsrCheckbox: FRAMEWORK_IDS.BRSR_CHECKBOX,
    griCheckbox: FRAMEWORK_IDS.GRI_CHECKBOX,
    cdpCheckbox: FRAMEWORK_IDS.CDP_CHECKBOX,
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [checkboxError,setCheckboxError]=useState('');

  const handleCheckboxChange = (id) => {
    if (id === 'allCheckbox') {
      // If 'All' checkbox is selected, select all other checkboxes
      const allCheckboxes = Object.keys(checkboxIdMap);
      setSelectedCheckboxes(allCheckboxes);
    } else {
      // If any other checkbox is selected, toggle its selection
      const newSelectedCheckboxes = selectedCheckboxes.includes(id)
        ? selectedCheckboxes.filter((checkboxId) => checkboxId !== id)
        : [...selectedCheckboxes, id];
      setSelectedCheckboxes(newSelectedCheckboxes);
    }
  };

  const isAllCheckboxSelected = selectedCheckboxes.length === Object.keys(checkboxIdMap).length;

//  API integration
  const url= `${constants.BACKEND_SERVER_BASEURL}/users/addFramework`;
  const handleNext = async () => {
    const selectedIds = selectedCheckboxes.map((checkbox) => checkboxIdMap[checkbox]);
    // console.log('Selected IDs:', selectedIds);

    if (selectedIds.length === 0) {
      setCheckboxError("Please Select At Least One Framework");
      return false;
    } else {
      setCheckboxError("");

      try {
        const response = await axios.post(url, {
          frameworks: JSON.stringify(selectedIds),
          company_id:JSON.stringify(companyId)
        });
        //console.log('API Response:', response.data);
        onAction(selectedIds);
        return true;
      } catch (error) {
        console.error('Error making API request:', error);
        return false;
      }
    }
  };


  return (
    <div>
      {showModal && (
        <div className='overlay-styles'>
          <div className='framework-modal'>
            <div className='frameworks_card_container'>
              <div className='container_gap col-12'>
                <p className='framework_heading'>Frameworks Interested</p>
                <p className='framework_paragraph'>
                  We auto selected the mandatory ones as per your location details provided
                </p>
              </div>
              <div className='framework_image text-center'>
                <img src={environment} className='environment_image mx-auto' alt='environmentImage' />
              </div>
              <div className=' checkboxes_container d-flex justify-content-center flex-wrap '>
                <div className='frameworks_checkbox'>
                  <label htmlFor='csrdCheckbox' className='framework_checkbox_label'>
                    <input
                      type='checkbox'
                      id='csrdCheckbox'
                      className='framework_check_box'
                      name='framework'
                      value='CSRD'
                      checked={selectedCheckboxes.includes('csrdCheckbox')}
                      onChange={() => handleCheckboxChange('csrdCheckbox')}
                    />
                    CSRD
                  </label>
                </div>
                <div className='frameworks_checkbox'>
                  <label htmlFor='brsrCheckbox' className='framework_checkbox_label'>
                    <input
                      type='checkbox'
                      id='brsrCheckbox'
                      className='framework_check_box'
                      name='framework'
                      value='BRSR'
                      checked={selectedCheckboxes.includes('brsrCheckbox')}
                      onChange={() => handleCheckboxChange('brsrCheckbox')}
                    />
                    BRSR
                  </label>
                </div>
                <div className='frameworks_checkbox'>
                  <label htmlFor='griCheckbox' className='framework_checkbox_label'>
                    <input
                      type='checkbox'
                      id='griCheckbox'
                      className='framework_check_box'
                      name='griCheckbox'
                      value='GRI'
                      checked={selectedCheckboxes.includes('griCheckbox')}
                      onChange={() => handleCheckboxChange('griCheckbox')}
                    />
                    GRI
                  </label>
                </div>
                <div className='frameworks_checkbox'>
                  <label htmlFor='cdpCheckbox' className='framework_checkbox_label'>
                    <input
                      type='checkbox'
                      id='cdpCheckbox'
                      className='framework_check_box'
                      name='cdpCheckbox'
                      value='CDP'
                      checked={selectedCheckboxes.includes('cdpCheckbox')}
                      onChange={() => handleCheckboxChange('cdpCheckbox')}
                    />
                    CDP
                  </label>
                </div>
                <div className='frameworks_checkbox'>
                  <label htmlFor='allCheckbox' className='framework_checkbox_label'>
                    <input
                      type='checkbox'
                      id='allCheckbox'
                      className='framework_check_box'
                      name='allCheckbox'
                      value='ALL'
                      checked={isAllCheckboxSelected}
                      onChange={() => handleCheckboxChange('allCheckbox')}
                    />
                    All
                  </label>
                </div>
              </div>
              {checkboxError && (<p style={{ color: 'red' }}>{checkboxError}</p>)}
              <div className='buttonsss mt-5 d-flex justify-content-end'>
                  <button
                    type='button'
                    className='framework_back_button framework_next_button'
                    onClick={handleNext}
                  >
                    Next
                  </button>
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
