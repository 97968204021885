import React,{useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import UploadPage from './pages/UploadPage'
import Report from './pages/Report';
import Iterations from './pages/Iterations';
import SummaryReport from './pages/SummaryReport';
import Navbar from './components/Navbar';
import Signup from './components/Signup';
import Login from './components/Login';
import NavbarContext from './components/NavbarContext';
import NuggetsNavbar from './pages/NuggetsNavbar';
import NuggetsPage from './pages/NuggetsPage';
import DocPreviewPage from './pages/DocPreviewPage';
import ActionsEditPage from './pages/ActionsEditPage';
import AiModelTraining from './pages/AiModelTraining'
const App = () => {
  const [isLoginPage,setIsLoginPage] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <BrowserRouter>
    <NavbarContext.Provider value={{
        isLoginPage,
        setIsLoginPage, isExpanded, setIsExpanded
        
      }}>

      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/FileUpload" element={<UploadPage />} />
        <Route path="/DocPreview/:iterationId" element={<DocPreviewPage />} />
        <Route path="/Report/:iterationId/:companyId" element={<Report />} />
        <Route path="/Iterations" element={<Iterations />} />
        <Route path="/SummaryReport/:iterationId/:companyId" element={<SummaryReport />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Login" element={<Login />} />

        <Route path="/NuggetsPage/:iterationId" element={<NuggetsPage />} />
        <Route path="/NuggetsNavbar" element={<NuggetsNavbar />} />
        <Route path="/ActionsEditPage/:actionId" element={<ActionsEditPage />} />
        <Route path='/AiModelTraining' element={<AiModelTraining/>}/>

      </Routes>
      </NavbarContext.Provider>
    </BrowserRouter>
  );
};

export default App;
