import React,{useContext,useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import '../css/AiModelTraining.css';
import NavbarContext from '../components/NavbarContext';
import HeaderComponent from '../components/HeaderComponent';
import axios from "axios";
import moment from "moment";

const constants = require("../config/constants.js");

function AiModelTraining() {
  const { isExpanded } = useContext(NavbarContext);
  const [aiModelHistory,setAiModelHistory] = useState([]);
  const [aiModelData,setaiModelData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigate =useNavigate()
  useEffect(() => {
    const getUrl = async () => {
      try{
        const aiModelHistoryUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getModelHistory`;
        const response = await axios.get(aiModelHistoryUrl);
        setAiModelHistory(response.data.results);
      } catch (error){
        console.error("error",error)
      }
    };
      getUrl();
    }, []);

    useEffect(() => {
      const getUrl = async () => {
        try{
          const aiModelDataUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getTrainedModelData`;
          const response = await axios.get(aiModelDataUrl);
          setaiModelData(response.data.results);
        } catch (error){
          console.error("error",error)
        }
      };
        getUrl();
      }, []);

      const startReTrainProcess = async() =>{
        const reTrainUrl = `${constants.BACKEND_SERVER_BASEURL}/iterations/startRetrainProcess`;
        const retrainData = {version_id: aiModelData.current_model_version_id}
        try {
          const response = await axios.post(reTrainUrl, retrainData);
          navigate(0);
      } catch (error) {
          console.error('Error occurred while retraining:', error);
      }
    }
    const formatDate = (dateStr) => {
      return moment(dateStr).format('DD MMM YYYY hh:mma');
    }
  return (
    <div className={`home-container ${isExpanded ? 'expanded' : ''}`}>
        <HeaderComponent />
      <div className="container-fluid ai_page_container">
        <div className="row">
          <div className="mt-5 d-flex justify-content-between ai_heading_container border-bottom">
            <h1 className="mt-5 m-4 ai_heading">AI Model Training</h1>
            <h1 className={`mt-5 m-4 ${aiModelData.version_status_flag === 0 ? 'training_heading' : 'succeeded_heading'}`} >
              {( aiModelData.version_status_flag === 0) ? 'Training in progress..' : 'Succeeded'}
            </h1>
          </div>
          <div className="d-flex border-bottom">
          <div className="col-4 m-4 ai_data_card">
              <p className="current_model_data">Current Model Last Trained</p>
              <p className="current_model_data train_card">Current Trained Feedback Points</p>
          </div>
          <div className="col-5 m-4 ai_data_card">
            <p className="current_model_value">{formatDate(aiModelData.last_trained_datetime)}</p>
            <p className="current_model_value train_card">{aiModelData.keyinfo_feedback_points},{aiModelData.reference_feedback_points},{aiModelData.timeline_feedback_points}</p>
          </div>
          </div>
          <div className="d-flex">
          <div className="col-4 m-4 ai_data_card">
          <p className="current_model_data">Untrained Feedback Points</p>
          </div>
          <div className="col-5 m-4 ai_data_card">
            <p className="current_model_value train_card">{aiModelData.keyinfo_feedback_pending_points},{aiModelData.reference_feedback_pending_points},{aiModelData.timeline_feedback_pending_points}</p>
          </div>
          </div>
          <div className="untrained_card">   
           <button className={`${
              aiModelData.version_status_flag === 0 || 
             ( aiModelData.keyinfo_feedback_pending_points < 10 ||
              aiModelData.reference_feedback_pending_points < 10 ||
              aiModelData.timeline_feedback_pending_points < 10)
                ? 'train_model_disable_btn'
                : 'train_model_btn'
            }`} 
              onClick={startReTrainProcess}>
              Train Model
           </button>
           <h1 className="mt-5 ai_heading train_card model_card_container">Model History</h1>
          </div>
           {aiModelHistory.map((model) => <div className="d-flex model_history_card" key={model.ver_id}>
            <div className="col-4 m-4 ai_data_card ">
              <p className="current_model_data ">{model.version_name}</p>
            </div>
            <div className="col-3 m-4 ai_data_card">
              <p className="current_model_value ">{formatDate(model.version_date_time)}</p>
            </div>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default AiModelTraining
