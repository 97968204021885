import React from 'react'
import welcomeNote from '../../assets/Welcome.png'
import '../../css/PopUpCardsCSS/WelcomeCard.css';



export default function WelcomeCard({showModal, onAction}) {
  if (!showModal) return null;
  return (

    <div>
      
          <div className='overlay-styles'>
            <div className='custom-modal'>
              <div className=' welcome_card_container '>
                  <img src={welcomeNote} alt='welcomeNote' className='welcome_note_img'/>
                  <p className='welcome_heading '>Welcome!</p>
                  <p className='welcome_paragraph'> It seems like this is your first visit. To get started, just click here.</p>
                  <button className='start_button' onClick={onAction}>Start</button>
              </div>
            </div>
          </div>
        
        </div>
  
   
  )
}
