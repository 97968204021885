import '../css/HeaderComponent.css';
import companyLogo from '../assets/PEAK Logo@4x-8 1.png'
import { FaArrowRight } from "react-icons/fa6";
import { Link,useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState,useEffect,useContext } from 'react';
import moment from 'moment';
import NavbarContext from './NavbarContext.js';

const constants = require("../config/constants.js");


export default function HeaderComponent() {
  const {isExpanded} = useContext(NavbarContext);

  const [aiAssetDetails, setAiAssetDetails] = useState([]);

  const location = useLocation();
  const currentPath =location.pathname;

  const formatDate = (dateStr) => {
    return moment(dateStr).format('DD MMM YYYY hh:mma');
};


  useEffect(() => {
    const getUrl = async () => {
      try {
        const aiAssetUrl = `${constants.BACKEND_SERVER_BASEURL}/nuggets/getTrainedModelData`
        const response = await axios.get(aiAssetUrl);
        setAiAssetDetails(response.data.results);
      } catch (error) {
        console.error("error", error)
      }
    };
      getUrl();
    }, []);

    
  return (
    <div className='container-fluid'>
        <div className={`row topbar-container px-2 top-nav`}>
            <div className="col-1 d-flex flex-row align-items-center">
                <img src={companyLogo} alt='companyLogo' className='company-logo-image' />
            </div>
            <div className={`col-7 pt-1 feedback_card ${currentPath === '/AiModelTraining' && 'hide_ai_model'} ${currentPath === '/FileUpload' && 'hide_ai_model'} ${currentPath === '/' && 'hide_ai_model'}`}>
              <p className='ai_model_data_heading'>AI Model Trained with <span className='ai_model_data_points'>({aiAssetDetails.keyinfo_feedback_points},{aiAssetDetails.reference_feedback_points},{aiAssetDetails.timeline_feedback_points}) points</span></p>
              <p className='ai_model_data_heading last_trained'>Last Trained <span className='ai_model_data_points'>{formatDate(aiAssetDetails.last_trained_datetime)}</span></p>
            </div>
            <div className={`col-3 pt-1 ${currentPath === '/AiModelTraining' && 'hide_ai_model'} ${currentPath === '/FileUpload' && 'hide_ai_model'} ${currentPath === '/' && 'hide_ai_model'}`}>

              <p className={` ${isExpanded ? 'expanded_pending_training' : 'feedback_pending_training'}`}>Feedback Pending For Training ({aiAssetDetails.keyinfo_feedback_pending_points},{aiAssetDetails.reference_feedback_pending_points},{aiAssetDetails.timeline_feedback_pending_points})</p>
              <Link className='train_model_link' to={"/AiModelTraining"}><p className={`${isExpanded ? 'expanded_retrain_model_link' : 'retrain_model_link'}`}><span style={{cursor:'pointer'}}>Retrain Model  <FaArrowRight /></span></p></Link>
            </div>
            {/* <div className="col-9 d-flex justify-content-end align-items-center">              
              <span className='profile-pic-con'><img src={profilePic} className='profile-pic' /></span>
              <span className='profile-text'>Hi Sindhu</span> 
              <FaChevronDown />
            </div>         */}
        </div>
    </div>
  );
}
