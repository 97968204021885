import { useContext, useState } from 'react';
import '../css/Signup.css';
import Vector from '../assets/Vector.png';
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import SignupNavbar from './SignupNavbar';
import { useNavigate } from 'react-router-dom';
import NavbarContext from './NavbarContext';
import axios from 'axios';
import constants from '../config/constants';

export default function Signup() {
  const { setIsLoginPage } = useContext(NavbarContext);
  let navigate = useNavigate();

  const loginClick = () => {
    navigate('/Login');
    setIsLoginPage(true);
  }

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordType(passwordVisible ? "password" : "text");
  };

  const emailPattern = /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/;
  const passwordPattern=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/;
  const [email_error, setEmailError] = useState("");
  const [password_error, setPasswordError] = useState("");
  const [reEnterPassword_error, setReEnterPassword_error] = useState("");

  const [signupError, setSignupError] = useState("");
  const url = `${constants.BACKEND_SERVER_BASEURL}/users/registerUser`;

  const validateEmail = () => {
    
    if (email.trim() === "") {
      setEmailError("Enter Email Address");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Enter Valid Email Address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };
  
  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Enter Password");
      return false;
    } else if (password.trim().length < 8) {
      setPasswordError("Password should be at least 8 characters");
      return false;
    } else if (!passwordPattern.test(password)) {
      setPasswordError("Password must contain one digit, lowercase, uppercase, and one special character");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };
  
  const validateReEnterPassword = () => {
    if (reEnterPassword.trim() === "") {
      setReEnterPassword_error("Enter password");
      return false;
    } else if (reEnterPassword !== password) {
      setReEnterPassword_error("Password Does Not Match");
      return false;
    } else {
      setReEnterPassword_error("");
      return true;
    }
  };
  
  const handleSignup = async (e) => {
    e.preventDefault();
  
    var isValidEmail            = validateEmail();
    var isValidPassword         = validatePassword();
    var isValidReEnterPassword  = validateReEnterPassword();

    if (isValidEmail && isValidPassword && isValidReEnterPassword) {
      try {
        const response = await axios.post(url, { email, password });
        const data = response.data;
        if (data.error_code === 0) {
          localStorage.setItem('userId',data.user_id)
          navigate('/');
        } else if (data.error_code === 403) {
          setSignupError("User Already Exist");
        }
      } catch (error) {
        console.log(error);
      }
    }
  
    
  };
  

  return (
    <div>
      <div className='bg_container row'>
        <SignupNavbar />
        <div className='left_container col-12 col-lg-6  '>
          <div>
            <p className='signup_heading '>
              Your Guide to <span className='signup_heading_highlight'>Responsible Investing and Beyond</span>
            </p>
            <p className='signup_paragraph'>
              By using ESGPilot, businesses and organisations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
            </p>
          </div>
        </div>
        <div className='right_container col-12 col-lg-6'>
          <div className='form_container'>
            <form onSubmit={handleSignup}>
              <div className='input_box_logo '>
                <img src={Vector} alt='vector' className='vector_image' />
                <p className='esgpilot_heading'>ESG<span className='pilot'>PILOT</span></p>
              </div>
              {signupError && (<p style={{ color: 'red', textAlign: "center" }}>{signupError}</p>)}
              <div>
                <input
                  type='email'
                  placeholder='Email Address'
                  id='email'
                  name='email'
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                  className='input_box'
                />
              </div>
              {email_error && (<p style={{ color: 'red' }}>{email_error}</p>)}

              <div className='password_img_container'>
                <input
                  type={passwordType}
                  placeholder='Set Password'
                  name='password'
                  id='password'
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                  className='input_box'
                />
                {passwordVisible ? <PiEyeLight className='eye_image' onClick={togglePasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={togglePasswordVisibility} />}
              </div>
              {password_error && (<p style={{ color: 'red', width:"370px"}}>{password_error}</p>)}

              <div className='password_img_container'>
                <input type='password'
                  placeholder='Re-enter Password'
                  id='confirmPassword'
                  value={reEnterPassword}
                  onChange={(e) => { setReEnterPassword(e.target.value) }}
                  className='input_box' />
              </div>
              {reEnterPassword_error && (<p style={{ color: 'red' }}>{reEnterPassword_error}</p>)}

              <button type='submit' className='signup_button' >Signup</button>

              <p className='signup_text'>Already Have An Account? <span onClick={loginClick} className='login_link_sgu'>Login</span></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
