import React,{useState} from 'react'
import '../css/ActionsPage.css';
import { Link} from 'react-router-dom';
import StarRatings from 'react-star-ratings';



import { IoIosArrowUp,IoIosArrowDown } from "react-icons/io";
import { FaPencilAlt } from 'react-icons/fa';

function ActionsPage() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  
  const backendData = [
    {
      action_id: 1,
      actionName: 'Report',
      actionType: 'Payment',
      baseDate: 'Form Submission',
      modifierRule: '28',
      repetitionRule: 'none',
      sourceNugget: 'Click to go nugget',
      phase: 'Pre-lodged',
      process: 'Payment',
      rating: 5,
      description:
        'Lorem ipsum dolor sit amet consectetur. Lobortis dui orci vel risus turpis non. ...',
      totalFeedback:
        'Lorem ipsum dolor sit amet consectetur. Lobortis dui orci vel risus turpis non. ...',
    },
    {
      action_id: 2,
      actionName: 'Feedback',
      actionType: 'Survey',
      baseDate: 'Email Submission',
      modifierRule: '20',
      repetitionRule: 'daily',
      sourceNugget: 'Click to go nugget',
      phase: 'In Progress',
      process: 'Survey Collection',
      rating: 4,
      description:
        'Lorem ipsum dolor sit amet consectetur. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ...',
      totalFeedback:
        'Lorem ipsum dolor sit amet consectetur. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ...',
    },
    {
      action_id: 3,
      actionName: 'Task',
      actionType: 'Assignment',
      baseDate: 'Task Start',
      modifierRule: '15',
      repetitionRule: 'weekly',
      sourceNugget: 'Click to go nugget',
      phase: 'Completed',
      process: 'Assignment Submission',
      rating: 3,
      description:
        'Lorem ipsum dolor sit amet consectetur. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ...',
      totalFeedback:
        'Lorem ipsum dolor sit amet consectetur. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ...',
    },
    {
      action_id: 4,
      actionName: 'Event',
      actionType: 'Registration',
      baseDate: 'Event Date',
      modifierRule: '25',
      repetitionRule: 'monthly',
      sourceNugget: 'Click to go nugget',
      phase: 'Upcoming',
      process: 'Registration Confirmation',
      rating: 5,
      description:
        'Lorem ipsum dolor sit amet consectetur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ...',
      totalFeedback:
        'Lorem ipsum dolor sit amet consectetur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ...',
    },
  ];
  return (
    <div className='container-fluid '>
        <div className='row'>
            <div className=' actions_card_container'> 
                <div className='actions_container'>
                <div className=''>
                    <div className='mt-5'>
                    {backendData.map((item, index) => (
                    <div key={index} className='mb-4 actions_card'>
                        <div className='actions_table'>
                        <table>
                        <thead>
                            <tr className={`actions_table_heading ${expandedIndex === index ? 'actions_table_headings expanded' : ''}`}>
                            <th>Action Name</th>
                            <th>Action Type</th>
                            <th>Base Date</th>
                            <th>Modifier Rule</th>
                            <th>Repetition Rule</th>
                            <th>Source Nugget</th>
                            <th className='actions_table_heading expand_arrow_icon' onClick={() => handleToggle(index)}>
                                {expandedIndex !== index ? (
                                <IoIosArrowDown className='arrow_icon' />
                                ) : (
                                <IoIosArrowUp className='arrow_icon up_arrow' />
                                )}
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`actions_table_data ${expandedIndex === index ? 'actions_table_expansion' : ''}`}>
                            <td >{item.actionName}</td>
                            <td >{item.actionType}</td>
                            <td >{item.baseDate}</td>
                            <td >{item.modifierRule}</td>
                            <td >{item.repetitionRule}</td>
                            <td className='actions_table_data navigate_nugget'>{item.sourceNugget}</td>
                            <td ></td>
                            </tr>
                        </tbody>
                        </table>
                        </div>

                        {expandedIndex === index && (
                        <div className='expansion_card_container'>
                            <div className='d-flex justify-content-between actions_exp_card'>
                            <div className='d-flex  expansion_card'>
                                <div className='phase_element'>
                                <p className='actions_table_heading_card phase_heading '>Phase</p>
                                <p className='actions_table_data_card action_table_heading'>{item.phase}</p>
                                </div>
                                <div className='phase_element'>
                                <p className='actions_table_heading_card phase_heading'>Process</p>
                                <p className='actions_table_data_card action_table_heading'>{item.process}</p>
                                </div>
                            </div>

                            <div className='d-flex justify-content-end mt-2 action_star_rating'>
                                <StarRatings
                                rating={item.rating}
                                starRatedColor='orange'
                                numberOfStars={5}
                                name='rating'
                                starDimension='15px'
                                starSpacing='1px'
                                className='action_star_rating'
                                />
                            </div>
                            </div>

                            <div>
                            <p className='actions_table_heading_card actions_table_description phase_heading'>Description</p>
                            <p className='actions_table_data_card actions_table_description'>{item.description}</p>
                            </div>
                            <div>
                            <p className='actions_table_heading_card actions_table_description phase_heading'>Total Feedback</p>
                            <p className='actions_table_data_card actions_table_description'>{item.totalFeedback}</p>
                            </div>
                            <div className='d-flex justify-content-end '>
                            {/* <Link to={`/ActionsEditPage/${item.action_id}`} className="edit-link">
                                <FaPencilAlt className='pencil_icon' />
                                Click to Edit
                            </Link> */}
                            {/* <Link to={{ pathname: `/ActionsEditPage/${item.action_id}`, state: { actionData: item } }} className="edit-link">
                              <FaPencilAlt className='pencil_icon' />
                              Click to Edit
                            </Link> */}
                            <Link to={`/ActionsEditPage/${item.action_id}?actionData=${JSON.stringify(item)}`} className="edit-link">
                              <FaPencilAlt className='pencil_icon' />
                              Click to Edit
                            </Link>
                            </div>
                        </div>
                        )}
                    </div>
                    ))}
                </div>
                </div>
            </div> 
            </div>
        </div>
    </div>
    
  )
}

export default ActionsPage
