import {useMemo, useState, useEffect} from 'react'
import {useTable, useSortBy} from 'react-table'
import '../css/DocListView.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import constants from '../config/constants';
import { FaSort, FaSortUp, FaSortDown  } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import moment from 'moment';
import DeleteConfirmationModal from '../components/PopUpCards/DeleteConfirmationModal';
import { ColorRing } from 'react-loader-spinner';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function DocListView({activeJuriId, tenementType, documentName}) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const url = `${constants.BACKEND_SERVER_BASEURL}/iterations/getAllIterations`

  const [documentsList, setDocumentsList] = useState([])
  const [activeIteration, setActiveIteration] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setLoader(true);
      const response = await axios.get(url);
      if (tenementType !== '') {
        const filteredIterations = response.data.filter(item => String(item.tenement_type.toLowerCase()).includes(tenementType.toLowerCase()));
        setDocumentsList(filteredIterations)
      } else if (documentName !== '') {
        const filteredIterations = response.data.filter(item => String(item.document_name.toLowerCase()).includes(documentName.toLowerCase()));
        setDocumentsList(filteredIterations)
      } else if (activeJuriId !== '0') {
        const filteredIterations = response.data.filter(item => String(item.juri_id) === String(activeJuriId));
        setDocumentsList(filteredIterations);
      }  
      else {
        setDocumentsList(response.data);
      }
    }
    getData()
    setLoader(false);
  }, [activeJuriId, tenementType, documentName])

  // const viewIteration = (iterationId) => {
  //   navigate(`/NuggetsPage/${iterationId}`);
  // };
  // const viewPreview = (iterationId) => {
  //   navigate(`/DocPreview/${iterationId}`);
  // };
  const onRow=(iterationStatus,fromPage,toPage,iterationId)=>{
    if(iterationStatus === 3){
      navigate(`/NuggetsPage/${iterationId}`)
    }else if (iterationStatus !==3 && (fromPage === null && toPage === null)){
      navigate(`/DocPreview/${iterationId}`)
    }
  }

  const onDeleteButton = (iteration_id) => {
    setActiveIteration(iteration_id)
    setShowDeleteModal(true)
  }

  const columns = [
    {
      Header: 'S.No',
      accessor: 'serialNumber',
      disableSortBy: true,
      Cell: ({row}) => row.index + 1,
    },
    {
      Header: 'Jurisdiction',
      accessor: 'juri_name_short'
    },
    {
      Header: 'Tenement Name',
      accessor: 'tenement_type'
    },
    {
      Header: 'Document',
      accessor: 'document_name'
    },
    {
      Header: 'Pages',
      accessor: 'pages',
      Cell: ({ row }) => {
        const { from_page_no, to_page_no } = row.original;
        if (from_page_no === null && to_page_no === null) {
          return 'Pages are not selected';
        } else if (from_page_no === 0 && to_page_no === 0) {
          return 'All Pages';
        } else {
          return `${from_page_no}-${to_page_no}`;
        }
      },
      sortType: (rowA, rowB, columnId) => {
        const { from_page_no: pageAFrom, to_page_no: pageATo } = rowA.original;
        const { from_page_no: pageBFrom, to_page_no: pageBTo } = rowB.original;
        const totalPagesA = (pageAFrom !== null && pageATo !== null) ? pageATo - pageAFrom + 1 : Infinity;
        const totalPagesB = (pageBFrom !== null && pageBTo !== null) ? pageBTo - pageBFrom + 1 : Infinity;
        return totalPagesA - totalPagesB;
      }
    },
    {
      Header: 'Uploaded',
      accessor: 'inserted_datetime',
      Cell: ({ value }) => {
        const formattedDate = moment(value).format('DD MMM YYYY');
        return formattedDate;
      },
      sortType: (rowA, rowB) => {
        const dateA = new Date(rowA.original.inserted_datetime);
        const dateB = new Date(rowB.original.inserted_datetime);
        return dateA - dateB;
      },
    },
    
    {
      Header: 'Action',
      accessor: 'action_text',
      Cell: ({ row }) => (
        <button className={`view-button ${row.original.iteration_status !== 3 && 'remove-access'} ${row.original.from_page_no === null && row.original.to_page_no === null && 'remove-events'}`} onClick={()=>onRow(row.original.iteration_status,row.original.from_page_no,row.original.to_page_no,row.original.iteration_id)}>
          View <GoArrowRight className={`arrow-icon`} />
        </button>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Delete',
      accessor: '',
      Cell: ({ row }) => (
        <button className='list-delete-button' onClick={() => onDeleteButton(row.original.iteration_id)}><MdDeleteOutline className='list-delete-icon' /></button>
      ),
      disableSortBy: true,
      className: 'list-delete-cell',
    },
  ]

  const columnsWithoutArrowIcons = columns.map((column) => ({
    ...column,
    disableSortBy: column.accessor === 'serialNumber' || column.accessor === 'action_text',
  }));

  const updatedColumns = useMemo(() => columnsWithoutArrowIcons, [])

  const tableInstance =  useTable({
    columns: updatedColumns,
    data: documentsList
  }, useSortBy)

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = tableInstance

  return (
    <div className='container-fluid mt-5 px-4'>

      <div className='row'>
        {
          loader ? 
          <div className='loader-container'>
            <ColorRing visible={true} height="70" width="70" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B', '#CB5B2B']}/>
          </div>
          :
          <>
          <div className='col-12'>
            <table {...getTableProps()}>
              <thead>
                {
                  headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                            <div  className={column.Header === 'Delete' ? 'center-delete-header' : 'heading-block'}>
                              {column.render('Header')} 
                              {column.Header === 'S.No' || column.Header === 'Action' || column.Header === 'Delete' ? <p></p> : <p className='sort-icons'>
                                {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />) : <FaSort />}
                              </p>}
                            </div>
                          </th>
                        ))
                      }
                      
                    </tr>
                  ))
                }
                
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <Tippy
                    content={
                      row.original.from_page_no === null && row.original.to_page_no === null ? (
                        <span>Pages are not selected. Click on the "View" to specify the page range.</span>
                      ) : (
                        row.original.iteration_status === constants.ITERATION_STATUS.DONE ? (
                          <span>Process Completed</span>
                        ) : (
                          row.original.iteration_status === constants.ITERATION_STATUS.PROCESSING_ITERATION ? (
                            <span>In Progress</span>
                          ) : (
                            row.original.iteration_status === constants.ITERATION_STATUS.DATA_INSERTED_SUCCESSFULLY ? (
                              row.original.is_re_run_flag === constants.ITERATION_STATUS.DATA_INSERTED_SUCCESSFULLY  ?(
                                <span>Re-Run is in progress</span>
                              ):(
                              <span>Waiting for process to start</span>
                              )
                            ) : (
                              row.original.error_message
                            )
                          )
                        )
                      )
                    } 
                      placement='bottom'
                      animation="fade" 
                      duration={200} 
                      theme="custom-theme"
                      className='custom-tool-tip'
                    >
                      <tr {...row.getRowProps()} className={row.original.iteration_status !== 3 && 'remove-access'}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                        })}
                      </tr>
                    </Tippy>
                  );
                })}
              </tbody>
                          </table>
          </div>
          </>
        }
      </div>
          {
            showDeleteModal && <DeleteConfirmationModal showModal={showDeleteModal} onExit={() => setShowDeleteModal(false)} iterationId={activeIteration}/>
          }
    </div>
  )
}

export default DocListView
